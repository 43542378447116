.contact-form {
    position: relative;
    width: 100%;
    background-color: $background-black-2;
    padding-bottom: 60px;
}

.contact-form-header {
    width: 100%;
    background-color: $white;
    padding: 30px 0 100px;

    .product-info-pod__label {
        color: $primary-gold;
        opacity: 1;
        font-family: $GTAmericaMedium;

        &::before {
            background-color: $primary-gold;
        }
    }

    h1, h2, h3, h4, p {
        @include font-size(24,40);
        text-transform: uppercase;
        font-family: $GTAmericaMedium;
        letter-spacing: 0.18em;
        margin-bottom: 16px;
    }
}

// button--black button--black-on-light

.contact-form-wrapper {
    background-color: $pale-grey;
    padding: 45px 90px;
    margin-top: -64px;

    @include media($small-desktop) {
        padding: 45px;
    }

    @include media($small) {
        padding: 26px;
    }

    .select-wrapper {
        select,
        .select-arrow {
            background-color: $pale-grey;
        }
    }
}