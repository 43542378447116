.image-gallery {
    padding: 60px 0 80px;
    background-color: $background-black;

    @include media($small) {
        padding: 50px 0 46px;
    }

    + .rich-text-container {
        padding-top: 0;
    }
}

.image-gallery-slider {
    img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }

    picture {
        display: block;
    }
}

.image-gallery-arrows-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    padding: 0 96px;

    .slick-arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        padding: 0;
        margin: 0;
        pointer-events: auto;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: $bg-light-grey-2;
            border-radius: 100%;
            transition: background-color 0.3s ease, transform 0.3s ease;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            width: 10px;
            height: 10px;
            top: 24px;
            font-size: 0;
            border-left: 1px solid $carousel-arrow;
            border-bottom: 1px solid $carousel-arrow;
        }

        &.slick-prev {
            &::after {
                left: 26px;
                transform: rotate(45deg);
            }
        }

        &.slick-next {
            &::after {
                left: 22px;
                transform: rotate(225deg);
            }
        }

        &:hover {
            &::before {
                transform: translate(-50%,-50%) scale(1.14);
                background-color: $pale-grey;
            }
        }
    }
}

.image-gallery-dots-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 23px;

    @include media($small) {
        margin-top: 24px;
    }

    .slick-dots {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 0;

        li {
            display: inline-block;
            margin: 0 1px;

            button {
                padding: 17px;

                &::before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: transparent;
                    border: 1px solid $white;
                    border-radius: 100%;
                    opacity: 0.5;
                    transition: background-color 0.3s ease;
                }
            }

            &.slick-active,
            &:hover {
                button::before {
                    background-color: $white;
                    opacity: 1;
                }
            }
        }
    }
}