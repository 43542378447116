.two-column-block {
    padding: 45px 0 65px;
    background-color: $background-black-2;
    overflow: hidden;

    @include media-min($max-tablet) {
        padding: 95px 0 105px;
    }

    @include media($small) {
        padding-bottom: 35px;
    }

    &.-bg-black {
        background-color: $background-black;
    }

    &.-flip {
        background-color: $background-black;

        .two-column-block__inner {
            @include media-min($max-tablet) {
                flex-direction: row-reverse;
            }
        }

        .two-column-block__content-col {
            @include media-min($max-tablet) {
                padding-right: 0;
                padding-left: 105px;

                + .two-column-block__content-col {
                    // padding-left: 0;
                    // padding-right: 105px;
                    order: -1;
                }
            }
        }

        .two-column-block__image-col {
            @include media-min($max-tablet) {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-end;
            }

            @include media($max-tablet) {
                text-align: left;
                padding-left: 0;
            }
        }
    }

    &--white-bg {
        background-color: $white;
    }

    h1, h2, h3, h4 {
        text-transform: none;
        color: $white;
        letter-spacing: 0.02em;
        font-family: $GTAmericaLight;
    }
}

.two-column-block__inner {
    display: flex;

    @include media($max-tablet) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
}

.two-column-block__content-col,
.two-column-block__image-col,
.two-column-block__video-col {
    width: 100%;

    @include media-min($max-tablet) {
        width: 50%;
    }
}

.two-column-block__content-col {
    max-width: 570px;
    @include no-bottom-margin(); 

    @include media-min($max-tablet) {
        max-width: none;
        padding-right: 105px;
    }

    @include media-min($mid-small-desktop) {
        min-width: 420px;
    }

    @include media($max-tablet) {
        padding-left: 32px;
        padding-right: 32px;
        max-width: none;

        + .two-column-block__content-col {
            margin-top: 40px;
        }
    }

    p {
        color: rgba($white,0.7);
        margin-bottom: 2rem;

        .two-column-block.two-column-block--white-bg & {
            color: $black;
        }

        a {
            text-decoration: underline;
            font-family: $GTAmericaMedium;
            color: inherit;
            transition: color 0.2s ease-in;
  
            &:hover {
                color: $primary-gold;
            }
        }
    }

    .button {
        margin: 2em 50px 1em 0;
    }
}

.two-column-block__image-col {
    figure {
        display: inline-block;
        text-align: left;
        margin: 0;
    }

    figcaption {
        width: 100%;
        color: rgba($white,0.7);
        background-color: $dark-grey;
        padding: 10px 15px;
    }

    @include media($max-tablet) {
        order: -1;
        text-align: right;
        margin-top: -45px;
        margin-bottom: 58px;

        figure {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
            display: inline-block;
            vertical-align: bottom;
        }
    }
}



.two-column-block__imageCaptionInner {
    @include media-min($mid-small-desktop) {
        max-width: calc(100% - 420px);
    }
}

.two-column-block__video-col {
    @include media($max-tablet) {
        order: -1;
        margin-top: -45px;
        margin-bottom: 58px;
    }
}