.faqs-container {
    background-color: $white;
    padding: 70px 32px;
    overflow: hidden;

    @include media($small) {
        padding: 32px 32px 42px;
    }

    + .rich-text-container {
        padding-top: 0;
    }
} 
  
.faqs-container-inner {
    width: 100%;
    max-width: 745px;
    margin: 0 auto;

    > h2,h3,h4,h5,h6 {
        margin-bottom: 1.75em;
    }
}