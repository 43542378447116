.content-listing-container {
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
  }

  .content-listing-wrapper {
    display: flex;
    flex-direction: column;

    .content-listing {
      position: relative;
      margin-bottom: 30px;
      height: 243px;

      .img {
        height: 100%;
        overflow: hidden;

        &:before {
          content:'';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba( 0, 0, 0, .3 );
          z-index: 1;
        }
      }
      .content-listing-image {
        height: 100%;
        z-index: 0;
      }

      .content-listing-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 2;

        a {
          color: $white;
          

          &.small {
            text-transform: uppercase;
            letter-spacing: 1.76px;
            @include font-size(11);
          }
        }
      }
    }
  }
}



@media (min-width: $max-tablet) {
  .content-listing-container {
    .content-listing-wrapper {
      flex-direction: row;
      .content-listing {
        flex: 1;
        margin-left: 12px;
        height: 382px;
        margin-bottom: 0;

        &:first-of-type {
          margin-left: 0;
          margin-right: 12px;
        }

        
      }
    }
  }
}