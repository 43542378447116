.rich-text-container {
    background-color: $white;
    padding: 70px 32px;
    overflow: hidden;

    @include media($small) {
        padding: 25px 32px;
    }

    + .faqs-container {
        padding-top: 0;
    }

    .cookiebot-declaration {
        width: 100%;
        max-width: 745px;
        margin: 0 auto;
        margin: $horizontal-space auto;
    }
}
  
.rich-text {
    width: 100%;
    max-width: 745px;
    margin: 0 auto;

    h1,h2,h3,h4,h5,h6,ul,ol,table,p {
        + img {
            margin-top: 50px;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        margin-top: 48px;

        &:first-child {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -15px;
                left: 0;
                width: 40px;
                height: 1px;
                background-color: $primary-gold;
            }
        }
    }
  
    p,li {
        a {
            text-decoration: underline;
            font-family: $GTAmericaMedium;
            color: inherit;
            transition: color 0.2s ease-in;
  
            &:hover {
                color: $primary-gold;
            }
        }
    }
  
    table {
        width: 100%;
        display: block;
        overflow: auto;
        overflow-y: hidden;
        overflow-x: auto;
        border-collapse: collapse;
        margin: 30px 0;
        height: auto !important; // override tinyMce forced height attribute
    
        th,td {
            padding: 12px 15px;
            letter-spacing: 0.06em;
            min-width: 185px;
            height: auto !important; // override tinyMce forced height attribute

            @include media($small) {
                &:first-child {
                    padding-top: 16px;
                }
                
                &:not(:last-child) {
                    padding-bottom: 0;
                }

                &:last-child {
                    padding-bottom: 16px;
                }
            }
        }

        tr {
            height: auto !important; // override tinyMce forced height attribute
        }
    
        thead {
            @include media($small) {
                display: none;
            }

            th {
                text-align: left;
                background-color: $dark-grey;
                font-weight: bold;
                color: $white;
                @include font-size(14,20);
            }
        }
    
        tbody {
            tr {
                @include media($small) {
                    display: block;
                }

                &:nth-child(odd) {
                    td {
                        background-color: $bg-light-grey;
                    }
                }
        
                &:nth-child(even) {
                    td {
                        background-color: $bg-light-grey-2;
                    }
                }
            }

            @include media($small) {
                display: block;

                td {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    
    ul,ol {
        list-style-type: none;
        margin-bottom: 2em;
        padding: 0;
    }

    li {
        position: relative;
        @include font-size(12,24);
        letter-spacing: 0.06em;

        &:not(:last-child) {
            padding-bottom: 1em;
        }
    }

    ul {
        li {
            padding-left: 28px;

            &:before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                width: 6px;
                height: 6px;
                background-color: $primary-gold;
                border-radius: 100%;
            }
        }
    }

    ol {
        counter-reset: count;

        li {
            padding-left: 28px;
            counter-increment: count;

            &:before {
                content: counter(count) '.';
                position: absolute;
                top: 1px;
                left: 0;
                color: $primary-gold;
                font-family: $GTAmericaMedium;
            }
        }
    }

    blockquote {
        position: relative;

        &:before,
        &:after {
            content: '\0201C';
            position: absolute;
            font-style: normal;
            top: 0;
            left: 0;
        }

        &:after {
            content: '\0201D';
        }

        &:after {
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
        }
    }

    img {
        position: relative;
        display: block;
        width: 100vw;
        height: auto;
        margin: 20px auto;
        left: 50%;
        transform: translateX(-50%);
    }

    > *:last-child {
        margin-bottom: 0;

        img {
            margin-bottom: -70px;

            @include media($small) {
                margin-bottom: -45px;
            }
        }
    }
}