﻿#CybotCookiebotDialog {
	font-family: GT-America-Extended-Light !important;
	font-size: 14px !important;
}

.CybotCookiebotDialogContentWrapper {

	@media(min-width: $max-desktop) {
		width: 1200px;
		max-width: 1200px !important;
	}

	#CybotCookiebotDialogHeader {
		display: none !important;
	}
}
#CybotCookiebotDialogBodyButtonAccept {
	&.CybotCookiebotDialogBodyButton {
		background-color: #000000 !important;
		border-color: #000000 !important;
	}
}
#CybotCookiebotDialogBodyButtonDecline {
	&.CybotCookiebotDialogBodyButton {
		border: 1px solid #000000 !important;
	}
}

#CybotCookiebotDialogBodyEdgeMoreDetails {
	#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
		color: #000000 !important;
	}
}
