﻿.container {

    .max-840-left {


        @media (min-width: $max-tablet) {
            max-width: 840px;
        }

        @media (min-width: $max-desktop) {
            padding: 0;
        }
    }


    &.standard-content {

        a {
            text-decoration: underline;
            color: $black;
            font-family: 'GT-America-Expanded-Medium';
        }

        p {
            @media (min-width: $max-tablet) {
                max-width: 840px;
            }
        }

        strong {
            color: $black;
        }

        ul {
            list-style: none;

            li {
                @include font-size(12,24);
                letter-spacing: 0.6px;
                color: $content-txt;
                margin: .75rem 0;

                &:before {
                    content: "\2022";
                    color: $content-txt;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: li;

            li {
                color: $content-txt;
                counter-increment: li;
                //font-family: GT-America-Extended-Light;

                &:before {
                    content: counter(li);
                    color: $content-txt;
                    // font-family: GT-America-Extended-Light;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1.5em;
                    margin-right: 0.5em;
                    text-align: right;
                    direction: rtl;
                }
            }
        }
    }



    .image-caption {
        //font-family: 'GT-America-Extended-Light';
        color: $caption-txt-grey;
        padding-top: 1.5rem;
        justify-content: center;

        &:before {
            opacity: .5;
        }
    }

    .img-caption-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;
        width: 100%;
        height: 137px;

        @media (min-width: $max-tablet) {
            height: 268px;
        }

        @media (min-width: $small-desktop) {
            height: 440px;
        }
    }

    .curver-border-bottom-right {
        border-radius: 0px 0px 6rem 0px;

        @media (min-width: $small-desktop) {
            border-radius: 0px 0px 10rem 0px;
        }
    }
}

.image-gallery-carousel {
    position: relative;
    width: 100%;

    &.product-page { 

        width: calc(100% + 60px);
        margin: 0 -30px;      
        

        #image-gallery .fill {
            height: 324px;
        }

        @media (min-width: $max-tablet) {
            border-bottom-left-radius: $curve-desktop;
            overflow: hidden;
            width: 100%;
            margin: 0;

            #image-gallery .fill {
                height: 440px;
            }
           
        }
    }

    h2 {
        margin-bottom: 3rem;
        @include font-size(24,32);

        &:before {
            height: 43px;
            margin-right: 31px;
        }

        @media (min-width: $max-tablet) {
            @include font-size(32,40);
        }
    }

    #image-gallery {

        .fill {
            overflow: hidden;
            background-size: cover;
            background-position: center;
            height: 323px;
            position: relative;

            @media (min-width: $max-tablet) {
                height: 560px;
                position: relative;
            }
        }

        .slick-slide {
            position: relative;
        }

        .slider-caption {
            position: relative;
            bottom: -1.5rem;
            width: 100%;
            padding: 0 24px;
            text-align: center;

            @media (min-width: $max-tablet) {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);

                & p {
                    color: $white;
                }

                bottom: 1rem;
            }
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            display: none;

            & > svg {
                display: none;
            }

            &.slick-prev {
                left: 150px;
                z-index: 99;
            }

            &.slick-next {
                right: 86px;
            }

            &:hover {
                cursor: pointer;
                background: $black;
                transition: $duration $easing;

                & > svg {
                    fill: $white;
                }
            }

            @media (min-width: $max-tablet) {

                & > svg {
                    display: block;
                }

                background: $white;
                border-radius: 50%;
                padding: 24px 24px;
                display: block;
                width: 64px;
                height: 64px;
            }
        }
    }

    .slider-info {
        letter-spacing: .6px;
        text-align: center;
        color: $black;
        display: flex;
        flex-direction: column;
        margin: 2rem;
       // font-family: 'GT-America-Extended-Light';
        padding: 0 6rem;


        @media (min-width: $max-tablet) {
            color: $white;
            position: absolute;
            bottom: 4rem;
            z-index: 9;
            transform: translate(-50%, -50%);
            left: 50%;
            flex-direction: column-reverse;
            margin: 0;
            padding: 0;
        }

        .custom_paging {
            margin-top: .75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'GT-America-Expanded-Medium';

            @media (min-width: $max-tablet) {
                margin-bottom: .75rem;
            }

            .slider-pag-first {
                margin-right: 1.5rem;
            }

            .slider-pag-second {
                margin-left: 1.25rem;
                opacity: 0.5;
            }

            .pad-divider {
                display: inline-flex;
                margin: 0 2rem;

                &:before {
                    content: '';
                    width: 1px;
                    height: 35px;
                    background-color: $black;
                    opacity: 0.5;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);

                    @media (min-width: $max-tablet) {
                        background-color: $white;
                    }
                }
            }
        }
    }
}

    .circular-block-wrapper {

        margin: 0;

        .circular-block-item {
            margin-top: 3rem;
            flex-wrap: wrap;
            

            @media (min-width: $max-tablet) {
                
                padding: 0 24px;
            }

            .content {
                padding: 0 0 0 24px !important;
                width: calc( 100% - 100px );

                @media (min-width: $small-desktop) {
                    width: calc( 100% - 144px );
                }
            }

            .cta {
                width: 100%;
                display: flex;
                margin-top: 32px;

                 @media (min-width: $small-desktop) {
                   padding-left: 168px;
                   margin-top: 0;
                }
            }

            .img {
                height: 100px;
                border-radius: 50%;
                width: 100px;   
                border: 1px solid $border;            

                @media (min-width: $small-desktop) {
                    height: 144px;
                    max-width: 144px;
                    min-width: 144px;
                }
            }
        }
    }

    .image-block {
        overflow: hidden;

        img {
          max-width: 100%;
          height: auto;
        }

       
    }

.listing-item {
    &.image-text-block {
        padding: 0;
    }
}
.listing{
    &.alternate {
        &.light {
            .listing-item {
                &.image-text-block {
                    &:after {
                        content: normal;
                    }
                }
            }
        }
    }
}

.contactUsBlock {
    background-color: $white;
    padding-top: 45px;
    padding-bottom: 45px;

    @include media-min($max-tablet) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &-inner {
        width: 100%;
        max-width: 745px;
        margin: 0 auto;

        > * {
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }

            h4 {
                margin-bottom: 1rem;
            }
        }
    }
}



