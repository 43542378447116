.contact-details {
    padding: 90px 0;
    background-color: $lighter-grey;

    h2 {
        font-family: 'GT-America-Extended-Light';
        text-transform: none;
    }

    &__text {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        strong {
            font-family: 'GT-America-Extended-Light';
        }
    }

    &__external-link {

        margin-top: 40px;
        text-transform: uppercase;

        .icon-flag {
            width: 16px;
            height: 12px;
        }

        strong {
            margin-left: 5px;
        }
    }
}