.newsletter-pod {
    padding: 80px 0;
    background-color: $background-black;
    color: $white;

    h2,h3,h4 {
        color: $white;
    }

    @include media($max-tablet) {
        padding: 40px 0;
    }

    // .product-info-pod__label {
    //     margin-bottom: 1.5rem;
    // }
}

.newsletter-pod-inner {
    @include media-min($max-tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}

.newsletter-pod__content {
    @include media-min($max-tablet) {
        width: 40%;
    }

    p {
        color: rgba($white,0.7);
    }
}

.newsletter-pod__form {
    margin-top: 30px;

    @include media-min($max-tablet) {
        width: 60%;
        margin-top: 30px;
        padding-left: 60px;
    }

    label {
        opacity: 0.6;
    }

    input {
        border-bottom-color: rgba($white,0.3);

        &::placeholder {
            color: rgba($white,0.3);
        }

        &:focus-within,
        &:hover {
            border-bottom-color: $white;
        }
    }

    .checkbox-wrapper {
        label {
            &::before {
                border-color: $background-black;
                box-shadow: 0 0 0 1px $white;
            }
        }

        input[type=checkbox]:checked + label,
        input[type=checkbox]:checked + input + label {
            opacity: 1;

            &::before {
                background-color: $white;
            }
        }
    }

    .button--grey {
        border-color: rgba($white,0.3);
        color: rgba($white,0.7);

        .button__flourish {
            background-color: rgba($white,0.3);
        }

        &:hover {
            .button__flourish {
                background-color: $primary-gold;
            }
        }
    }
}

.newsletter-pod__title {
    display: block;
    margin: 0.75rem 0 1.5rem;
    @include font-size(24,40);
}

// button--white button--white-on-dark