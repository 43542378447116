.lightbox {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 43px 90px;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s $extremeEasingOut, visibility 0.3s $extremeEasingOut;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.5;
    }

    &.-active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

.lightbox-player {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 1280px;
    margin: 0 auto;
}

.lightbox-player-wrapper {
    position: relative;
    padding-top: 56.25%;
    width: 100%;

    iframe,video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.lightbox-close {
    position: absolute;
    top: -18px;
    right: -42px;
    font-size: 0;
    transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
    cursor: pointer;

    &:hover {
        transform: rotate(180deg);
    }
}