.primary-nav {
    // debug only
    @include media($max-mobile-nav) {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        transform: translateY(-50px);
        transition: transform 0.25s $extremeEasingOut;
        pointer-events: none;
        background-color: $dropdown-bg;
        padding: 50px 32px 85px;
        height: calc(100vh - 50px);
        z-index: 1;
        overflow: auto;

        .site-header.-nav-open & {
            transform: translateY(100%) translateY(1px);
            pointer-events: auto;
        }
    }

    > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 0;

        @include media($max-mobile-nav) {
            border-bottom: 1px solid rgba($white,0.1);
            margin-bottom: 22px;
        }

        > li {
            display: inline-block;
            color: rgba($white,0.7);
            margin: 0 20px;
            font-size: 12px;
            line-height: 10px;
            text-transform: uppercase;
            letter-spacing: 3px;
            transition: color 0.3s ease;

            @include media-min($max-mobile-nav) {
                font-size: 10px;
                padding: 19px 0;
                margin: 0 18px;

                &:hover {
                    color: $white;
                }

                .site-header.site-header--light & {
                    color: rgba($black,0.7);

                    &:hover {
                        color: $black;
                    }
                }
            }

            @include media-min($small-desktop) {
                font-size: 12px;
            }

            @include media($max-mobile-nav) {
                display: block;
                text-transform: none;
                color: $white;
                font-size: 24px;
                line-height: 30px;
                margin: 0 0 30px;
            }

            a {
                display: inline-block;
                color: inherit;
                text-decoration: none;
                margin: 0;
            }

            &.has-children {
                @include media-min($max-mobile-nav) {
                    a {
                        margin-right: 5px;
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        position: relative;
                        top: -1px;
                        width: 11px;
                        height: 11px;
                        border-bottom: 1px solid rgba($white,0.7);
                        border-left: 1px solid rgba($white,0.7);
                        transform: rotate(-45deg);
                        transform-origin: 30% 70%;
                        transition: border-color 0.25s $extremeEasingOut, transform 0.3s ease, top 0.3s ease;

                        .site-header.site-header--light & {
                            border-color: rgba($black,0.7);
                        }
                    }

                    &:hover,
                    &:focus-within[aria-haspopup="true"] {

                        &:after {
                            transform: rotate(135deg);
                        }

                        .sub-menu {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                > a {
                    @include media($max-mobile-nav) {
                        position: relative;
                        display: block;
                        padding-right: 40px;
                        margin: 0;

                        .sub-menu-toggle {
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            top: 0;
                            right: 0;

                            &:before,
                            &:after {
                                content: '';
                                background-color: $white;
                                position: absolute;
                            }

                            &:before {
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                width: 100%;
                                height: 1px;
                            }

                            &:after {
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 1px;
                                height: 100%;
                                transition: transform 0.25s $extremeEasingOut;
                            }
                        }

                        + .sub-menu {
                            display: none;
                            position: relative;
                            opacity: 1;
                            visibility: visible;
                            transform: none;
                            padding: 24px 16px 0;
                            background-color: transparent;

                            ul {
                                li {
                                    text-transform: uppercase;
                                    font-size: 10px;
                                    line-height: 10px;
                                    background: none !important;
                                    margin-bottom: 20px;

                                    img {
                                        display: none;
                                    }

                                    .overlay-link {
                                        position: static;
                                        height: auto;
                                        font-size: inherit;
                                    }

                                    .readMore {
                                        display: none;
                                    }
                                }
                            }

                            &.-show-sub-menu {
                                .sub-menu-toggle {
                                    &:after {
                                        transform: translateX(-50%) rotate(90deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .sub-menu {
            @include media-min($max-mobile-nav) {
                left: 0;
                right: 0;
                top: 100%;
                bottom: auto;
                transform: none;

                > ul {
                    width: 100%;
                    max-width: 1368px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;

                    > li {
                        position: relative;
                        width: 25%;
                        padding-top: 32.75%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        text-align: center;

                        @include media($small-desktop) {
                            width: 50%;
                            padding-top: 50%;
                        }

                        @include media($max-tablet) {
                            padding-top: 65.5%;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba($black,0.5);
                            pointer-events: none;
                            transition: background-color 0.5s $extremeEasingOut;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);

                            @include media($max-tablet) {
                                max-width: 57%;
                            }
                        }
                        // .readMore {
                        //     position: absolute;
                        //     display: flex;
                        //     align-items: center;
                        //     justify-content: center;
                        //     font-size: 11px;
                        //     line-height: 11px;
                        //     letter-spacing: 0.22em;
                        //     margin: 0;
                        //     padding: 0 15px;
                        //     color: $white;
                        //     text-transform: uppercase;
                        //     bottom: 11%;
                        //     left: 50%;
                        //     transform: translateX(-50%);
                        //     width: 100%;
                        //     transition: opacity 0.3s $extremeEasingOut;
                        //     width: auto;
                        //     @include media($small-desktop) {
                        //         font-size: 9px;
                        //     }
                        //     @include media($small) {
                        //         font-size: 8px;
                        //     }
                        //     span {
                        //         flex-shrink: 0;
                        //     }
                        //     // &::after {
                        //     //     content: '';
                        //     //     display: block;
                        //     //     width: 40px;
                        //     //     height: 1px;
                        //     //     margin-left: 16px;
                        //     //     background-color: $white;
                        //     //     transition: width 0.3s $extremeEasingOut;
                        //     //     @include media($small) {
                        //     //         display: none;
                        //     //     }
                        //     // }
                        // }

                        .readMore {
                            bottom: 60px;
                            // padding-top: 15px;
                            // padding-bottom: 15px;
                            z-index: 1;
                        }

                        @media (prefers-reduced-motion: no-preference) {
                            &:hover {
                                &::before {
                                    background-color: $primary-gold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // "Footer links" within the mobile navigation
    .site-footer-links {
        margin-bottom: 8px;

        li {
            display: block;
            margin-left: 0;
            margin-bottom: 15px;
        }
    }
    // "Footer" social links within the mobile nav
    .social-links {
        padding-top: 18px;
        justify-content: flex-start;

        a {
            margin: 0 20px 0 0;
        }
    }
    // "Footer" logos within the mobile nav
    .site-footer-logos {
        margin-top: 30px;

        p {
            margin: 0 0 7px;
        }
    }

    .site-footer-logos-listing {
        justify-content: flex-start;
    }
}

.mobile-nav-only {
    @include media-min($max-mobile-nav) {
        display: none;
    }
}
