.product-info-pod {
    padding: 45px 0 65px;
    background-color: $background-black-2;
    overflow: hidden;

    @include media-min($max-tablet) {
        padding: 95px 0 125px;
    }

    @include media($small) {
        padding-bottom: 35px;
    }

    &.-flip {
        background-color: $background-black;

        .product-info-pod__inner {
            @include media-min($max-tablet) {
                flex-direction: row-reverse;
            }
        }

        .product-info-pod__content-col {
            @include media-min($max-tablet) {
                padding-right: 0;
                padding-left: 105px;
            }
        }

        .product-info-pod__image-col,
        .product-info-pod__gallery-col {
            @include media-min($max-tablet) {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
    }

    &--white-bg {
        background-color: $white;

        h2 {
            @include media-min($max-tablet) {
                margin-top: 5rem;
            }
        }

        + .partner-brands-listing {
            .partner-brands-listing__heading {
                padding-top: 0;
            }
        }
    }

    &__gallery {
        position: relative;

        @include media-min($max-tablet) {
            width: calc(100% + 32px);
            max-width: 672px;
        }

        @include media-min($max-desktop) {
            width: 50vw;
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            vertical-align: bottom;
        }

        picture {
            display: block;
        }
    }

    &__galleryArrows {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;
        padding: 0 32px;

        @include media-min($small-desktop) {
            padding: 0 48px;
        }

        @include media-min($max-desktop) {
            padding: 0 64px;
        }

        .slick-arrow {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            padding: 0;
            margin: 0;
            pointer-events: auto;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-color: $bg-light-grey-2;
                border-radius: 100%;
                transition: background-color 0.3s ease, transform 0.3s ease;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 10px;
                height: 10px;
                top: 24px;
                font-size: 0;
                border-left: 1px solid $carousel-arrow;
                border-bottom: 1px solid $carousel-arrow;
            }

            &.slick-prev {
                &::after {
                    left: 26px;
                    transform: rotate(45deg);
                }
            }

            &.slick-next {
                &::after {
                    left: 22px;
                    transform: rotate(225deg);
                }
            }

            &:hover {
                &::before {
                    transform: translate(-50%,-50%) scale(1.14);
                    background-color: $pale-grey;
                }
            }
        }
    }

    &__galleryDots {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;

        @include media-min($max-tablet) {
            position: absolute;
            left: 0;
            bottom: -45px;
        }

        .slick-dots {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0;

            li {
                display: inline-block;
                margin: 0 1px;

                button {
                    padding: 8px;

                    &::before {
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        background-color: transparent;
                        border: 1px solid $white;
                        border-radius: 100%;
                        opacity: 0.5;
                        transition: background-color 0.3s ease;
                    }
                }

                &.slick-active,
                &:hover {
                    button::before {
                        background-color: $white;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.product-info-pod__inner {
    display: flex;
    align-items: flex-start;

    @include media($max-tablet) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
}

.product-info-pod__content-col,
.product-info-pod__image-col {
    width: 100%;

    @include media-min($max-tablet) {
        width: 50%;
    }
}

.product-info-pod__gallery-col {
    width: 100%;

    @include media-min($max-tablet) {
        width: 50%;
        max-width: calc(100% - 420px);
    }
}

.product-info-pod__content-col {
    max-width: 570px;
    @include no-bottom-margin(); 

    @include media-min($max-tablet) {
        min-width: 420px;
        max-width: none;
        padding-right: 105px;

        .product-info-pod.product-info-pod--white-bg & {
            + .product-info-pod__content-col {
                padding-top: 145px;
            }
        }
    }

    @include media($max-tablet) {
        padding-left: 32px;
        padding-right: 32px;

        .product-info-pod.product-info-pod--white-bg & {
            + .product-info-pod__content-col {
                padding-top: 30px;
                padding-left: 60px;
            }
        }
    }

    p {
        color: rgba($white,0.7);
        margin-bottom: 2rem;

        .product-info-pod.product-info-pod--white-bg & {
            color: $black;
        }

        a {
            text-decoration: underline;
            font-family: $GTAmericaMedium;
            color: inherit;
            transition: color 0.2s ease-in;
  
            &:hover {
                color: $primary-gold;
            }
        }
    }

    .button {
        margin: 2em 50px 2em 0;
    }
}

.product-info-pod__logo {
    display: block;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba($white,0.1);

    img {
        max-height: 62px;
        width: auto;
    }
}

.product-info-pod__label {
    position: relative;
    display: block;
    padding: 20px 0 20px 40px;
    @include font-size(10,16);
    font-family: $GTAmericaMedium;
    letter-spacing: 0.64em;
    text-transform: uppercase;
    color: $white;
    opacity: 0.4;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 1px;
        background-color: $white;
    }

    .product-info-pod.product-info-pod--white-bg & {
        color: $primary-gold;
        opacity: 1;

        &:before {
            background-color: $primary-gold;
        }
    }
}

.product-info-pod__sub-label {
    display: block;
    @include font-size(24,24);
    letter-spacing: 0.02em;
}

.product-info-pod__heading {
    display: block;
    @include font-size(32,48);
    font-family: $GTAmericaLight;
    text-transform: none;
    color: $white;
    margin-top: 0;
    margin-bottom: 1.5rem;

    @include media-min($small) {
        @include font-size(40,56);
        letter-spacing: 1px;
    }

    .product-info-pod.product-info-pod--white-bg & {
        color: $black;
    }

    .product-info-pod.product-info-pod--find-showroom & {
        @include media-min($small) {
            @include font-size(32,48);
            letter-spacing: 0.02em;
        }
    }
}

.product-info-pod__image-col
.product-info-pod__gallery-col {
    padding-top: 57px;

    @include media($max-tablet) {
        padding-top: 0;
        text-align: right;

        .product-info-pod:not(.product-info-pod.product-info-pod--white-bg) & {
            order: -1;
            margin-top: -45px;
            margin-bottom: 58px;
        }

        .product-info-pod.product-info-pod.product-info-pod--white-bg & {
            margin-top: 40px;
        }

        img {
            width: 100%;
            height: auto;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .product-info-pod.product-info-pod--find-showroom & {
        @include media-min($max-tablet) {
            padding-top: 17px;
        }
    }
}