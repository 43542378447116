.product-breadcrumb {
  @media (min-width: $breakpoint) {
		margin-top: 200px;
  }
}

.product-detail {
	padding-top: $spacing;
	padding-bottom: $vertical-space;
	display: flex;
	flex-direction: column;

	&__left {
		order: 3;

		.desktop {
			display: none;
		}
	}

	&__image {
		.images {
			display: flex;
			justify-content: left;
			position: relative;

			&__secondary {
				display: none;
			}

			img {
				border-bottom-right-radius: $curve-mob;
				border: 1px solid $lighter-grey;
        max-width: 100%;
			}
		}

		&--icon {
			position: absolute;
			bottom: 0;
			border-radius: 50px;
			height: 40px;
			min-width: 40px;
			background-color: $light-grey;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 20px;
		}

		.finish-select {
			padding: 20px 0 15px;
			ul {
				padding: 0;
				li {
					display: inline-block;
					margin-right: 10px;
				}
			}
			
			&__item {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					width: 40px;
					height: 40px;
					border-radius: 50px;
					margin-bottom: 10px;
					border: none;
					padding: 8px;
				}
			}

			.border {
				font-family: 'GT-America-Expanded-Medium';
				
				img {
					border: 1px solid $black;
				}
			}
			
		}
	}

	&__accordians {
		order: 3;
		display: flex;
		flex-direction: column;

		&--wrapper {
			padding: 10px 0;
			border-bottom: 1px solid $lighter-grey;

			ul {
				list-style: none;

				li {
					
				    letter-spacing: 0.6px;
				    color: $content-txt;

				    &:before {
					    content: "\2022";
					    color: rgba(0,0,0,.7);
					    display: inline-block;
					    width: 1em;
					    margin-left: -1em;
					}
				}
			}
		}

		&--single {
			padding: 10px 0;

			h4 {
				margin-bottom: 0;
			}

			.accordion {
				cursor: pointer;
				width: 100%;
				transition: $duration $easing;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: transparent;
				border: 0;
				padding: 0;

				.plus-icon {
					width: 20px;
					height: 20px;
					position: relative;

					&:before,
					&:after {
						content: "";
						position: absolute;
						background-color: $footer-text;
						transition: transform $duration $easing;
					}

					&:before {
						top: 0;
						left: 50%;
						width: 2px;
						height: 100%;
						margin-left: -1px;
					}

					&:after {
						top: 50%;
						left: 0;
						width: 100%;
						height: 2px;
						margin-top: -1px;
					}
				}


				&.active {
					.plus-icon {
						&:before {
							transform: rotate(90deg);
						}

						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}

			.panel {
				max-height: 0;
				overflow: hidden;
				transition: $duration $easing;

				&.active {
					max-height: 600px;
				}

				.wrapper {
					padding: 10px 0;

					ul {
						list-style: none;

						li {
							
						    letter-spacing: 0.6px;
						    color: $content-txt;

						    &:before {
							    content: "\2022";
							    color: rgba(0,0,0,.7);
							    display: inline-block;
							    width: 1em;
							    margin-left: -1em;
							}

							a {
								color: $content-txt;
							}
						}
					}

          &.download-panel {

            padding-bottom: 0;

            .product-download {

              padding: 15px;
              font-weight: 700;
              border-top: 1px solid $lighter-grey;

              &:nth-child(even) {
                background-color: $pale-grey;
              }

              a {
                margin-bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                  font-size: 10px;
                }
              }
            }

            .download-button {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top: 1px solid $lighter-grey;
              margin: 0;
              padding: 15px;
              text-transform: uppercase;
            }
          }
				}

				p:last-child {
					margin: 0;
				}
			}
		}

		.make-enquiry {
			margin-top: 30px;
		}
	}

	@media (min-width: $breakpoint) {
		flex-direction: row;

		.mobile {
			display: none;
		}

		&__left {
			order: 0;
			flex: 1;
			margin-right: 10px;
	
			.desktop {
				display: block;
			}
		}

		&__image {
			width: 50%;
			flex: 1;
			margin-left: 40px;

			.images {
				display: flex;
				flex-direction: column;

				img {
					border-bottom-right-radius: $curve-desktop;

				}
			}
		}
	}
}