.site-footer {
    padding: 0 0 60px 0;
    background-color: $background-black;

    @include media($max-mobile-nav) {
        text-align: center;
    }

    p {
        color: $text-footer;
        margin: 0;
    }

    .site-footer-logos {
        @include media-min($max-mobile-nav) {
            display: flex;
            align-items: center;
        }

        @include media($max-mobile-nav) {
            margin: 30px 0 15px;
        }
    }

    .site-footer-links {
        @include media($max-mobile-nav) {
            margin-top: 20px;
        }
    }
}

.site-footer-inner {
    padding-bottom: 10px;

    @include media-min($max-mobile-nav) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &.-border-top {
        padding-top: 40px;
        border-top: 1px solid rgba($white,0.1);
    }
}

.site-footer-logo {
    margin: 0;
}

.site-footer-links {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        color: rgba($white,0.45);
        padding: 12px 0;
        margin-left: 15px;

        @include media($max-mobile-nav) {
            margin-left: 7px;
            margin-right: 7px;
        }

        a {
            color: inherit;
            transition: color 0.25s ease;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        } 
    }
}

.social-links {
    display: flex;
    align-items: center;
    padding-top: 20px;

    @include media($max-mobile-nav) {
        justify-content: center;
    }

    a {
        display: block;
        font-size: 0;
        margin: 0 20px 0 0;

        @include media($max-mobile-nav) {
            margin: 0 10px;
        }

        > svg {
            fill: rgba($white,0.4);
            height: 20px;
            width: auto;
            transition: fill 0.25s ease;
        }

        > img {
            height: 20px;
            width: auto;
        }

        &:hover {
            > svg {
                fill: $white;
            }
        }
    }
}

.site-footer-logos-listing {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media($max-mobile-nav) {
        justify-content: center;
    }

    @include media($max-tablet) {
        gap: 0 24px;
    }

    > a,
    > span {
        position: relative;
        margin: 7px 0;

        &:not(:last-child) {
            margin-right: 49px;

            @include media($max-tablet) {
                margin-right: 0;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -24px;
                width: 1px;
                height: 24px;
                background-color: $white;
                transform: translateY(-50%);

                @include media($max-tablet) {
                    content: none;
                }
            }
        }
    }
}