.accordion {
  display: block;
  margin-top: 16px;

  /* for rich-text spacing purposes */
  +*:not(.accordion) {
    margin-top: 40px;
  }

  &.active {
    >.accordion-heading {
      background-color: $dark-grey-2;
      color: $white;

      .toggle {
        &::before,
        &::after {
          background-color: $white;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-90deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }
}

.accordion-heading {
  display: block;
  position: relative;
  padding: 23px 65px 23px 30px;
  border: 1px solid $black;
  color: $black;
  @include font-size(14,24);
  letter-spacing: 0.06em;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease;

  @include media($small) {
    padding: 15px 70px 15px 20px;
  }

  &:hover {
    background-color: $bg-light-grey-2;
  }

  .toggle {
    position: absolute;
    width: 26px;
    height: 26px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    background-color: transparent;
    backface-visibility: hidden;

    @include media($small) {
      right: 22px;
    }

    &::before,
    &::after {
      content: "";
      background-color: $black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease, background-color 0.25s ease;
    }

    &::before {
      height: 100%;
      width: 0.05em;
    }

    &::after {
      width: 100%;
      height: 0.05em;
    }
  }
}

.accordion-content {
  display: none;
  padding: 30px 30px 20px;

  @include media($small) {
    padding: 30px 20px;
  }

  >* {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Accordions within accordions */

.accordion-content > .accordion {
  margin-top: -1px;

  &:first-child {
    border-top: none;
  }

  .accordion-heading {
    background-color: transparent !important; /* override parent background colour */
    font-size: 18px;
    line-height: 30px;
    color: $black;
    text-transform: none;
    padding: 16px 32px;

    @include media($small) {
      padding: 16px 0 16px 32px;
    }

    .toggle {
      right: auto;
      left: 0;
      width: 20px;
      height: 20px;

      &:before,
      &:after {
        background-color: $black;
      }

      &:before {
        width: 1px;
      }

      &:after {
        height: 1px;
      }
    }
  }

  .accordion-content {
    padding: 0 0 45px 35px;

    @include media($small) {
      padding: 0 0 20px 35px;
    }
  }
}