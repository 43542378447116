﻿.download-group{
    margin-top: 32px;
}

.downloads-header {
    display: flex;
    flex-direction: column;

    @media (min-width: $max-tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &.no-cta {
        justify-content: flex-end;
      }
    }
}

.download-links {
    &.listing {
        flex-direction: column;
        align-items: center;
        .listing-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 20px;
            border-bottom: 1px solid $light-grey;
            width: calc(100% - 40px);
            align-items: center;

            &:nth-child(1) {
                border-top: 1px solid $light-grey;
            }

            .download-item {
                margin: 10px 48px 10px 0;
                align-self: center;
            }
        }

        @media (min-width: $max-tablet) {
            flex-direction: row;

            .listing-item {
                flex-direction: row;
                width: calc(50% - 10px);
                margin: 0;

                &:nth-child(2n) {
                    margin: 0 0 0 20px;
                    padding: 15px 20px;
                }

                &:nth-child(-n+2) {
                    border-top: 1px solid $light-grey;
                }
            }
        }
    }
}

.download-all {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px;

    .button{
        align-self: flex-end;
        margin-right: 66px;
    }

    @media (min-width: $max-tablet) {
        justify-content: flex-end;
    }
}