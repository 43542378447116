.button {
    position: relative;
    display: inline-block;
    padding: 15px 40px 15px 35px;
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.22em;
    // default white outline style
    border: 1px solid $white;
    color: $white;
    transform-origin: 0 50%;
    text-align: left;
    transition: border-color 0.6s $extremeEasingOut;

    @include media($max-mobile) {
        padding: 15px 35px 15px 30px;
    }    

    &__flourish {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%,-50%);
        width: 40px;
        height: 1px;
        // default style
        background-color: $white;
        transition: transform 0.6s $extremeEasingOut, background-color 0.6s $extremeEasingOut;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid transparent;
        height: 100%;
        width: 100%;
        transform: scale(0);
        pointer-events: none;
        background-color: transparent;
        transition: transform 0.6s $extremeEasingOut;
    }

    &::before {
        border-right: 1px solid $primary-gold;
        border-top: 1px solid $primary-gold;
        transform-origin: 100% 0;
    }

    &::after {
        border-left: 1px solid $primary-gold;
        border-bottom: 1px solid $primary-gold;
        transform-origin: 0 100%;
    }

    &:hover {
        border-color: transparent;

        .button__flourish {
            transform: translate(70%,-50%);
            background-color: $primary-gold;
        }

        &::before,
        &::after {
            transform: scale(1);
        }
    }
}

// Black outline styling
.button--black,
.button--black-on-light {
    border-color: $black;
    color: $black;

    .button__flourish {
        background-color: $black;
    }
}

// Grey outline styling (for forms)
.button--grey {
    border-color: rgba($black,0.3);
    color: inherit;

    .button__flourish {
        background-color: rgba($black,0.3);
    }
}

// Primary Gold outline styling
.button--primary-gold {
    border-color: $primary-gold;
    color: $primary-gold;

    &::before {
        border-right-color: $white;
        border-top-color: $white;
    }

    &::after {
        border-left-color: $white;
        border-bottom-color: $white;
    }

    .button__flourish {
        background-color: $primary-gold;
    }

    &:hover {
        .button__flourish {
            background-color: $white;
        }
    }
}

// Black fill styling
.button--fill-black {
    padding: 18px 35px;
    background-color: $black;
    color: $white;
    border: none;
    transition: background-color 0.3s ease;

    .button__flourish {
        display: none;
    }
    
    &:hover {
        background-color: $dark-grey-2;
    } 
}

.text-link {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    @include font-size(10,10);
    letter-spacing: 0.22em;
    padding-bottom: 5px;
    color: $white;
    transition: color 0.6s $extremeEasingOut;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: $white;
        transform-origin: 50% 0;
        transition: transform 0.6s $extremeEasingOut;
    }

    &--primary-gold {
        color: $primary-gold;

        &::after {
            background-color: $primary-gold;
        }

        &:hover {
            color: $white;

            &::after {
                transform: scaleY(3);
            }
        }
    }
}

.external-link {
    display: inline-flex;
    align-items: center;

    svg {
        width: 14px;
        height: 14px;
        margin-left: 6px;
        margin-bottom: 2px;
    }
}