
@font-face {
  font-family: 'GT-America-Extended-Light';
  src: url('/assets/src/css/fonts/GT-America-Extended-Light.woff') format('woff'), 
  url('/assets/src/css/fonts/GT-America-Extended-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GT-America-Expanded-Medium';
  src: url('/assets/src/css/fonts/GT-America-Expanded-Medium.woff') format('woff'), 
  url('/assets/src/css/fonts/GT-America-Expanded-Medium.woff2') format('woff2');
  font-display: swap;
}

$GTAmericaLight: 'GT-America-Extended-Light', Helvetica, sans-serif;
$GTAmericaMedium: 'GT-America-Expanded-Medium', Helvetica, sans-serif;

p, ul, a {
  margin-bottom: $spacing-small;
  //  font-family: 'GT-America-Extended-Light';
}


h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  font-family: $GTAmericaMedium;
  color: $black;
  letter-spacing: 0.18em;
  text-transform: uppercase;

  #lanyard_root & {
      letter-spacing: normal;
      text-transform: none;
  }

}

h1, .h1 {
  @include font-size(32,48);
  margin-bottom: 2rem;
}

h2, .h2 {
  @include font-size(24,40);
  margin-bottom: 24px;
  margin-bottom: 2rem;
}

h3, .h3,
h4, .h4 {
  @include font-size(18,32);
  margin-bottom: 1.5rem;
}

p {
  @include font-size(14,26);
  letter-spacing: 0.06em;
}

@media(min-width: $max-tablet) {
  h1, .h1 {
    @include font-size(48,64);
    margin-bottom: 2rem;
    letter-spacing: 8px;
  }

  h2, .h2 {
    @include font-size(32,48);
  }

  h3, .h3 {
    @include font-size(24,40);
  }

  h4, .h4 {
    @include font-size(18,32);
  }

}
 
.sub-heading   {

   @include font-size(12, 12);
   letter-spacing: .6px;
   display: flex;
   align-items: center;
    padding-left: 11px;
    margin-bottom: 16px;

    &:before {
      content: '';
      width: 1px;
      height: 32px;  
      background-color: black;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-right: 24px;
    }

    &.semi-trans {
      opacity: .5;
    }

    &.white {

      opacity: .5;
      color: $white;

      &:before {
          background-color: white;
      }
    }
  }


