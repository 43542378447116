/*** Global and page-specific form style ***/
fieldset {
    border: none;
    padding: 0;
    margin: 0;

    legend {
        @include font-size(16,24);
        margin-bottom: 1em;

        @include media-min($max-tablet) {
            @include font-size(18,32);
        }
    }
}

label {
    display: block;
    margin-bottom: 5px;
    @include font-size(12,18);
    letter-spacing: 0.06em;

    &.hidden {
        height: 0 !important;
        width: 0 !important;
        font-size: 0 !important;
        margin: 0 !important;
    }
}

button {
    border: none;
    background-color: transparent;
}

input[type=date],
input[type=email],
input[type=file],
input[type=text],
input[type=radio],
input[type=checkbox] {
    display: block;
    width: 100%;
    padding: 8px 0 13px;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid rgba($black,0.3);
    background-color: transparent;
    border-radius: 0;
    transition: border-bottom-color 0.3s ease, padding-left 0.35s ease;
    letter-spacing: 0.06em;

    @include placeholder-styles() {
        color: rgba(0,0,0,0.5);
    }

    &:focus-within,
    &:hover {
        outline: 0;
        border-bottom-color: $black;
    }

    &:focus-within {
        + .field-validation-error {
            &:before {
                opacity: 0;
            }
        }
    }

    &[type=radio],
    &[type=checkbox] {
        height: auto;
    }
}

textarea {
    width: 100%;
    padding: 15px 22px;
    margin-bottom: 30px;
    background-color: $white;
    border: none;

    @include placeholder-styles() {
        color: rgba($black,0.7);
    }
}

.select-wrapper,
select {
    width: 100%;
}

select {
    padding: 8px 30px 13px 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba($black,0.3);
    border-radius: 0;
    transition: border-bottom-color 0.3s ease;
    appearance: none;

    &:focus {
        outline: 0;
    }

    &:focus-within,
    &:hover {
        border-bottom-color: $black;
    }
}

.select-wrapper {
    position: relative;
    background-color: transparent;
    margin-bottom: 30px;

    // Included in mark-up for accessibility
    label {
        height: 0 !important;
        width: 0 !important;
        font-size: 0 !important;
        margin: 0 !important;
    }

    .select-arrow {
        position: absolute;
        right: 0; /* for border */
        top: 4px;
        width: 26px;
        height: 26px; /* height of select-wrapper, minus 2 for border */
        background-color: transparent;
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 6px;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid $black;
            border-right: 2px solid $black;
            transform: rotate(45deg);
        }
    }

    select:hover + .select-arrow {
        transform: scaleY(-1);
    }
}

input[type=radio],
input[type=checkbox] {
    position: absolute;
    left: -9999px;
}

.radio-wrapper,
.checkbox-wrapper {
    margin-bottom: 30px;

    label {
        position: relative;
        padding: 5px 0 5px 26px;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            top: 6px;
            left: 0;
            background-color: transparent;
        }
    }

    // Inline styles
    &.inline {
        label {
            display: inline-block;
            margin-right: 30px;
        }
    }

    + .field-validation-error {
        &::before {
            opacity: 0;
        }
    }
}

.radio-wrapper {
    label {
        &::before {
            border-radius: 100%;
            border: 1px solid $black;
        }
    }

    input[type=radio]:checked + label,
    input[type=radio]:checked + input + label {
        &::before {
            background-color: $black;
        }
    }
}

.checkbox-wrapper {
    label {
        &::before {
            content: '';
            font-size: 14px;
            line-height: 18px;
            box-shadow: 0 0 0 1px $dark-grey-3;
            border: 2px solid $pale-grey;
            opacity: 0.6;
        }
    }

    input[type=checkbox]:checked + label,
    input[type=checkbox]:checked + input + label {
        &::before {
            content: '';
            background-color: $black;
            opacity: 1;
        }
    }
}

/*** Umbraco Forms custom styles ***/
.umbraco-forms-fieldset {
    > .row-fluid {
        @include media-min($max-tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .umbraco-forms-container {
                &.col-md-6 {        
                    width: calc(50% - 18px);
                }
            }
        }
    }
}

.umbraco-forms-container {
    width: 100%;
}

.umbraco-forms-field-wrapper {
    position: relative;
}

.umbraco-forms-navigation {
    margin-top: 20px;
}

.field-validation-error {
    position: static;
    display: block;
    margin: -20px 0 20px;
    color: $error-msg;

    &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 2px;
        height: 20px;
        background-color: $error-msg;
        opacity: 1;
        transition: opacity 0.3s ease;
    }
}

.input-validation-error {
    input:not(:focus-within) {
        padding-left: 10px;
    }

    select {
        padding-left: 8px;
    }
}

.umbraco-forms-submitmessage {
    display: block;
    @include font-size(24,40);
    letter-spacing: 0.18em;
}

.g-recaptcha {
    transform-origin: 0 0;

    + .field-validation-error {
        display: none;
    }
}