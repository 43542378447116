.site-header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  background-color: $background-black;
  border-bottom: 1px solid rgba($white,0.1);
  margin-bottom: -51px;
  z-index: 10;

  @include media-min($max-mobile-nav) {  
    &--light {
      background-color: $background-off-white;
      border-bottom-color: rgba($black,0.1);
    }
  }

  @include media($max-mobile-nav) {
    padding: 0 27px 0 0;
    height: 50px;
    background-color: $dropdown-bg;

    > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 49px;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: $dropdown-bg;
    }
  }

  p {
    color: rgba($white,0.4);
  }
}

.site-header-logo {
  margin: 0 auto;
  padding-top: 3px;

  img {
      display: none;
  }

  .mobile-logo {
    display: block;
  }

  @include media-min($max-mobile-nav) {
    padding: 12px 0;
    margin: 0;

    .mobile-logo {
      display: none;
    }

    .desktop-logo {
      display: block;
      width: 32px;
    }
  }
}

.language-selector {
  position: relative;
  font-size: 12px;
  line-height: 10px;
  color: rgba($white,0.7);
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-right: 15px;
  cursor: pointer;

  @include media-min($max-mobile-nav) {
    font-size: 10px;
    padding: 19px 15px 19px 0;
    margin-left: auto;

    .site-header.site-header--light & {
      color: rgba($black,0.7);
    }
  }

  @include media-min($small-desktop) {
    font-size: 12px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid rgba($white,0.7);
    border-left: 1px solid rgba($white,0.7);
    transform: rotate(-45deg);
    transform-origin: 50% 75%;
    transition: border-color 0.25s $extremeEasingOut, transform 0.3s ease, top 0.3s ease;

    @include media-min($max-mobile-nav) {
      top: 18px;

      .site-header.site-header--light & {
        border-color: rgba($black,0.7);
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -33px;
    height: 34px;
    right: 0;
    width: 100%;
    background-color: transparent;
    opacity: 0;
  }

  .language-selection {
    transition: color 0.25s $extremeEasingOut;
    margin: 0;
  }

  &:hover,
  &.-active,
  &:focus-within[aria-haspopup="true"] {
    &:before {
      transform: rotate(135deg);
      border-color: $white;

      @include media-min($max-mobile-nav) {  
        .site-header.site-header--light & {
          border-color: $black;   
        }
      }
    }

    .language-selection {
      color: $white;

      @include media-min($max-mobile-nav) {  
        .site-header.site-header--light & {
          color: $black;
        }
      }
    }

    .sub-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.language-selector-text {

  &-mobile {
    display: none;
  }

  @include media($max-mobile-nav) {
    display: none;

    &-mobile {
      display: block;
    }
  }
}

.sub-menu {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 27px;
  transform: translateY(100%) translateY(17px);
  background-color: $background-black-2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s $extremeEasingOut, visibility 0.25s $extremeEasingOut;

  @include media-min($max-mobile-nav) {  
    .site-header--light & {
      background-color: $background-off-white-2;
    }
  }

  @include media($max-mobile-nav) {
    background-color: $black; 
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      padding: 12px 0;
      line-height: 14px;
    }
  }

  .language-selector-container {
    .region,
    .-current {
      color: $white;

      p {
        color: $white;
        margin-bottom: 0;
        font-weight: 600;
      }

      a {
        color: $white;
      }

      .languages {

        margin-left: 10px;

        li {
          color: rgba($white,0.5);
          transition: color 0.25s $extremeEasingOut;

          &:last-of-type {
            padding-bottom: 0;
          }

          &:hover {
            color: $white;
          }
        }
      }

      @include media-min($max-mobile-nav) {  
        .site-header--light & {

          p {
            color: $black;
          }

          a {
            color: rgba($black,0.7);

            &:hover {
              color: $black;
            }
          }
        }
      }
    }
  }

}

.language-selector-options {
  @include media($max-mobile-nav) {
    min-width: 200px;
  }
}

.language-selector-option {
  > a {
    display: block;
    margin: 0;
  }
}

.menu-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 58px;
  border-right: 1px solid rgba($white,0.1);

  @include media-min($max-mobile-nav) {
    display: none;
  }
}

.menu-icon {
  height: 20px;
  width: 23px;
  cursor: pointer;
  padding: 9px 0;
  display: inline-block;
  position: relative;
  z-index: 2;

  span {
    position: relative;
    height: 1px;
    width: 33px;
    display: block;
    background-color: transparent;

    &.middle {
      transition: background-color 0.25s $easing 0.25s;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        height: 1px;
        width: 23px;
        background-color: $white;
      }

      &:before {
        top: -3px;
        transition: top 0.25s $easing 0.25s, transform 0.25s $easing 0s;
      }

      &:after {
        bottom: -3px;
        transition: bottom 0.25s $easing 0.25s, transform 0.25s $easing 0s; 
      }
    }
  }

  &.-nav-open {
    .middle {
      background-color: transparent;
      transition-delay: 0s;

      &:before {
        top: 0;
        transform: rotate(-45deg);
        transition: top 0.25s $easing 0s, transform 0.25s $easing 0.25s; 
      }

      &:after {
        bottom: 0;
        transform: rotate(45deg);
        transition: bottom 0.25s $easing 0s, transform 0.25s $easing 0.25s; 
      }
    }
  }
}