.hero-area {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  // padding: 120px 32px 170px; // 50px covers the height of the header
  height: auto;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: $background-black;
 
  @include media-min($max-tablet) {
    padding: 90px;

    &:not(.contactus-hero) {
      height: 100vh;
    }

    min-height: 600px;
    max-height: 768px;
  }

  @include media($small) {
    background-image: none !important; // override inline style
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.7;
    z-index: 1;
    pointer-events: none;
  }

  &--light {
    &:not(.has-img) {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:before {
      background-color: $white;
      opacity: 0.75;
    }

    &.contactus-hero {
      &:before {
        background-color: $white;
        opacity: 1;
      }
    }
  }
}

.hero-area-bg-image,
.hero-area-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-area-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media-min($small) {
    display: none;
  }
}

.hero-area-bg-video {
  @media (prefers-reduced-motion) {
      display: none;
  }
    
  @include media($small) {
    display: none;
  }

  video {
    width: 100%;
    height: auto;
    min-width: 1380px;
  }
}

.hero-area-inner {
  position: relative;
  max-width: $small-desktop;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  padding: 120px 0px 0px;

  @include media-min($max-tablet) {
    padding: 90px 0px 0px;
  }

  h1,.h1,
  .hero-area-inner-text {
    color: $white;

    .hero-area.hero-area--light & {
      color: $black;
    }
  }

  .hero-area-inner-text {
    @include font-size(16,24);
    max-width: 760px;
    margin: 0 auto;

    @include media-min($max-tablet) {
      @include font-size(18,32); 
    }

    p {
      @include font-size(16,24);

      @include media-min($max-tablet) {
        @include font-size(18,32);
      }
    }
  }

  .button {
    margin-top: 50px;

    @include media($max-mobile) {
      &.js-lightbox {
        display: none;
      }
    }
  }
}

.hero-explore {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-items: center;
  letter-spacing: 0.86px;
  color: $white;
  z-index: 1;
  padding-bottom: 50px;

  @include media-min($max-tablet) {
    bottom: 135px;
  }

  .wrap {
    text-align: center;
    width: 22px;
    height: 11px;
    cursor: pointer;
    margin-top: 20px;
  }

  svg {    
    width: auto;
  }

  .wrap svg {
    width: 100%;
    height: 100%;
  }

  .bounce {
    animation: bounce 1.5s infinite;
    will-change: transform;
  }

  @keyframes bounce {
	0%, 100% { transform: translateY(0)}
	30% { transform: translateY(10px)}
  }
}
.hero-area-logo {
  margin-bottom: 75px;

  @include media($max-tablet) {
    display: none;
  }
}