.partner-brands-listing__heading {
    padding: 40px;
    background-color: $white;
    text-align: center;

    h1, h2, h3, h4, span {
        @include font-size(32,48);
        font-family: $GTAmericaLight;
        letter-spacing: normal;
        text-transform: none;
        margin: 0;
    }
}

.partner-brands-listing__inner {
    width: 100%;
    max-width: 1368px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.partner-brands-listing__item {
    position: relative;
    width: 25%;
    padding-top: 32.75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    @include media($small-desktop) {
        width: 50%;
        padding-top: 50%;
    }

    @include media($max-tablet) {
        padding-top: 65.5%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black,0.5);
        pointer-events: none;
        transition: background-color 0.5s $extremeEasingOut;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @include media($max-tablet) {
            max-width: 57%;
        }
    }

    p {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.22em;
        margin: 0;
        padding: 0 15px;
        color: $white;
        text-transform: uppercase;
        bottom: 17%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s $extremeEasingOut;

        @include media($small) {
            font-size: 8px;
            opacity: 1;
        }

        span {
            flex-shrink: 0;
        }

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 1px;
            margin-left: 16px;
            background-color: $white;
            transition: width 0.3s $extremeEasingOut;

            @include media($small) {
                display: none;
            }
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        &:hover {
            &::before {
                background-color: $primary-gold;
            }

            p {
                opacity: 1;

                &::after {
                    width: 40px;
                }
            }
        }
    }
}