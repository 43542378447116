.icon {
    display: inline-block;
}

.icon-instagram {
    width: 20px;
    height: 20px;
}

.icon-pinterest {
    width: 15px;
    height: 20px;
}

.icon-linkedin {
    width: 15px;
    height: 15px;
}

.icon-close {
    width: 28px;
    height: 28px;
}