/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* convert to em from px */
/* convert to rem from px */
/* unit conversion used by font size */
/* Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/* Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/* media query mixins that use breakpoints set in constants.scss */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'GT-America-Extended-Light';
  src: url("/assets/src/css/fonts/GT-America-Extended-Light.woff") format("woff"), url("/assets/src/css/fonts/GT-America-Extended-Light.woff2") format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'GT-America-Expanded-Medium';
  src: url("/assets/src/css/fonts/GT-America-Expanded-Medium.woff") format("woff"), url("/assets/src/css/fonts/GT-America-Expanded-Medium.woff2") format("woff2");
  font-display: swap; }

p, ul, a {
  margin-bottom: 24px; }

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
  color: #000;
  letter-spacing: 0.18em;
  text-transform: uppercase; }
  #lanyard_root h1, #lanyard_root h2, #lanyard_root h3, #lanyard_root h4, #lanyard_root h5, #lanyard_root h6 {
    letter-spacing: normal;
    text-transform: none; }

h1, .h1 {
  font-size: 32px;
  font-size: 2.66667rem;
  line-height: 48px;
  line-height: 4rem;
  margin-bottom: 2rem; }

h2, .h2 {
  font-size: 24px;
  font-size: 2rem;
  line-height: 40px;
  line-height: 3.33333rem;
  margin-bottom: 24px;
  margin-bottom: 2rem; }

h3, .h3,
h4, .h4 {
  font-size: 18px;
  font-size: 1.5rem;
  line-height: 32px;
  line-height: 2.66667rem;
  margin-bottom: 1.5rem; }

p {
  font-size: 14px;
  font-size: 1.16667rem;
  line-height: 26px;
  line-height: 2.16667rem;
  letter-spacing: 0.06em; }

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 48px;
    font-size: 4rem;
    line-height: 64px;
    line-height: 5.33333rem;
    margin-bottom: 2rem;
    letter-spacing: 8px; }
  h2, .h2 {
    font-size: 32px;
    font-size: 2.66667rem;
    line-height: 48px;
    line-height: 4rem; }
  h3, .h3 {
    font-size: 24px;
    font-size: 2rem;
    line-height: 40px;
    line-height: 3.33333rem; }
  h4, .h4 {
    font-size: 18px;
    font-size: 1.5rem;
    line-height: 32px;
    line-height: 2.66667rem; } }

.sub-heading {
  font-size: 12px;
  font-size: 1rem;
  line-height: 12px;
  line-height: 1rem;
  letter-spacing: .6px;
  display: flex;
  align-items: center;
  padding-left: 11px;
  margin-bottom: 16px; }
  .sub-heading:before {
    content: '';
    width: 1px;
    height: 32px;
    background-color: black;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 24px; }
  .sub-heading.semi-trans {
    opacity: .5; }
  .sub-heading.white {
    opacity: .5;
    color: #fff; }
    .sub-heading.white:before {
      background-color: white; }

html,
body {
  min-height: 100%;
  font-size: 12px;
  background-color: #151515; }

body {
  position: relative;
  width: 100%;
  font-family: 'GT-America-Extended-Light';
  font-weight: 400;
  font-style: normal; }
  body.-nav-open {
    overflow: hidden; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.svg-sprite {
  display: none; }

b,
strong {
  font-family: 'GT-America-Expanded-Medium'; }

.svg-sprite {
  display: none; }

a {
  text-decoration: none;
  color: #000; }
  a:hover {
    text-decoration: none; }
  a.link-w-icon {
    display: flex;
    align-items: center; }
    a.link-w-icon span {
      margin-right: 8px; }

a:active,
a:hover,
button {
  outline: 0; }

img {
  border: 0; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

section {
  position: relative; }
  section.no-top {
    margin-top: 0; }
  section.no-bottom {
    margin-bottom: 0; }

.container {
  width: 100%;
  max-width: 1192px;
  margin: 0 auto;
  padding: 0 32px;
  position: relative; }
  .container.max-1300 {
    max-width: 1300px;
    padding: 0; }

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.border-top {
  border-top: 1px solid #DBDBDB;
  padding-top: 60px; }

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }

.overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 2; }

.cursor--default {
  cursor: default; }

.skip-to-main {
  position: absolute;
  top: -999999px;
  left: -999999px;
  opacity: 0;
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  z-index: 999999; }
  .skip-to-main:focus {
    left: 20px;
    top: 0;
    opacity: 1;
    transform: translateY(20px); }
  .skip-to-main .button {
    background-color: #151515; }

@media (prefers-reduced-motion) {
  body *,
  body *::before,
  body *::after {
    transition: none !important;
    animation-fill-mode: forwards !important;
    animation-iteration-count: 1 !important;
    animation-duration: .01s !important; } }

.site-header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  background-color: #151515;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: -51px;
  z-index: 10; }
  @media all and (min-width: 1301px) {
    .site-header--light {
      background-color: #F4F4F4;
      border-bottom-color: rgba(0, 0, 0, 0.1); } }
  @media all and (max-width: 1300px) {
    .site-header {
      padding: 0 27px 0 0;
      height: 50px;
      background-color: #2C2C2C; }
      .site-header > * {
        position: relative;
        z-index: 2; }
      .site-header:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 49px;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #2C2C2C; } }
  .site-header p {
    color: rgba(255, 255, 255, 0.4); }

.site-header-logo {
  margin: 0 auto;
  padding-top: 3px; }
  .site-header-logo img {
    display: none; }
  .site-header-logo .mobile-logo {
    display: block; }
  @media all and (min-width: 1301px) {
    .site-header-logo {
      padding: 12px 0;
      margin: 0; }
      .site-header-logo .mobile-logo {
        display: none; }
      .site-header-logo .desktop-logo {
        display: block;
        width: 32px; } }

.language-selector {
  position: relative;
  font-size: 12px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-right: 15px;
  cursor: pointer; }
  @media all and (min-width: 1301px) {
    .language-selector {
      font-size: 10px;
      padding: 19px 15px 19px 0;
      margin-left: auto; }
      .site-header.site-header--light .language-selector {
        color: rgba(0, 0, 0, 0.7); } }
  @media all and (min-width: 981px) {
    .language-selector {
      font-size: 12px; } }
  .language-selector:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    border-left: 1px solid rgba(255, 255, 255, 0.7);
    transform: rotate(-45deg);
    transform-origin: 50% 75%;
    transition: border-color 0.25s cubic-bezier(0, 0, 0.125, 1.06), transform 0.3s ease, top 0.3s ease; }
    @media all and (min-width: 1301px) {
      .language-selector:before {
        top: 18px; }
        .site-header.site-header--light .language-selector:before {
          border-color: rgba(0, 0, 0, 0.7); } }
  .language-selector:after {
    content: '';
    position: absolute;
    bottom: -33px;
    height: 34px;
    right: 0;
    width: 100%;
    background-color: transparent;
    opacity: 0; }
  .language-selector .language-selection {
    transition: color 0.25s cubic-bezier(0, 0, 0.125, 1.06);
    margin: 0; }
  .language-selector:hover:before, .language-selector.-active:before, .language-selector:focus-within[aria-haspopup="true"]:before {
    transform: rotate(135deg);
    border-color: #fff; }
    @media all and (min-width: 1301px) {
      .site-header.site-header--light .language-selector:hover:before, .site-header.site-header--light .language-selector.-active:before, .site-header.site-header--light .language-selector:focus-within[aria-haspopup="true"]:before {
        border-color: #000; } }
  .language-selector:hover .language-selection, .language-selector.-active .language-selection, .language-selector:focus-within[aria-haspopup="true"] .language-selection {
    color: #fff; }
    @media all and (min-width: 1301px) {
      .site-header.site-header--light .language-selector:hover .language-selection, .site-header.site-header--light .language-selector.-active .language-selection, .site-header.site-header--light .language-selector:focus-within[aria-haspopup="true"] .language-selection {
        color: #000; } }
  .language-selector:hover .sub-menu, .language-selector.-active .sub-menu, .language-selector:focus-within[aria-haspopup="true"] .sub-menu {
    opacity: 1;
    visibility: visible; }

.language-selector-text-mobile {
  display: none; }

@media all and (max-width: 1300px) {
  .language-selector-text {
    display: none; }
    .language-selector-text-mobile {
      display: block; } }

.sub-menu {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 27px;
  transform: translateY(100%) translateY(17px);
  background-color: #1C1C1C;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.125, 1.06), visibility 0.25s cubic-bezier(0, 0, 0.125, 1.06); }
  @media all and (min-width: 1301px) {
    .site-header--light .sub-menu {
      background-color: #E8E8E8; } }
  @media all and (max-width: 1300px) {
    .sub-menu {
      background-color: #000; } }
  .sub-menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .sub-menu ul li {
      display: block;
      padding: 12px 0;
      line-height: 14px; }
  .sub-menu .language-selector-container .region,
  .sub-menu .language-selector-container .-current {
    color: #fff; }
    .sub-menu .language-selector-container .region p,
    .sub-menu .language-selector-container .-current p {
      color: #fff;
      margin-bottom: 0;
      font-weight: 600; }
    .sub-menu .language-selector-container .region a,
    .sub-menu .language-selector-container .-current a {
      color: #fff; }
    .sub-menu .language-selector-container .region .languages,
    .sub-menu .language-selector-container .-current .languages {
      margin-left: 10px; }
      .sub-menu .language-selector-container .region .languages li,
      .sub-menu .language-selector-container .-current .languages li {
        color: rgba(255, 255, 255, 0.5);
        transition: color 0.25s cubic-bezier(0, 0, 0.125, 1.06); }
        .sub-menu .language-selector-container .region .languages li:last-of-type,
        .sub-menu .language-selector-container .-current .languages li:last-of-type {
          padding-bottom: 0; }
        .sub-menu .language-selector-container .region .languages li:hover,
        .sub-menu .language-selector-container .-current .languages li:hover {
          color: #fff; }
    @media all and (min-width: 1301px) {
      .site-header--light .sub-menu .language-selector-container .region p, .site-header--light
      .sub-menu .language-selector-container .-current p {
        color: #000; }
      .site-header--light .sub-menu .language-selector-container .region a, .site-header--light
      .sub-menu .language-selector-container .-current a {
        color: rgba(0, 0, 0, 0.7); }
        .site-header--light .sub-menu .language-selector-container .region a:hover, .site-header--light
        .sub-menu .language-selector-container .-current a:hover {
          color: #000; } }

@media all and (max-width: 1300px) {
  .language-selector-options {
    min-width: 200px; } }

.language-selector-option > a {
  display: block;
  margin: 0; }

.menu-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 58px;
  border-right: 1px solid rgba(255, 255, 255, 0.1); }
  @media all and (min-width: 1301px) {
    .menu-icon-wrapper {
      display: none; } }

.menu-icon {
  height: 20px;
  width: 23px;
  cursor: pointer;
  padding: 9px 0;
  display: inline-block;
  position: relative;
  z-index: 2; }
  .menu-icon span {
    position: relative;
    height: 1px;
    width: 33px;
    display: block;
    background-color: transparent; }
    .menu-icon span.middle {
      transition: background-color 0.25s cubic-bezier(0, 0, 0, 1) 0.25s; }
      .menu-icon span.middle:before, .menu-icon span.middle:after {
        content: '';
        position: absolute;
        left: 0;
        height: 1px;
        width: 23px;
        background-color: #fff; }
      .menu-icon span.middle:before {
        top: -3px;
        transition: top 0.25s cubic-bezier(0, 0, 0, 1) 0.25s, transform 0.25s cubic-bezier(0, 0, 0, 1) 0s; }
      .menu-icon span.middle:after {
        bottom: -3px;
        transition: bottom 0.25s cubic-bezier(0, 0, 0, 1) 0.25s, transform 0.25s cubic-bezier(0, 0, 0, 1) 0s; }
  .menu-icon.-nav-open .middle {
    background-color: transparent;
    transition-delay: 0s; }
    .menu-icon.-nav-open .middle:before {
      top: 0;
      transform: rotate(-45deg);
      transition: top 0.25s cubic-bezier(0, 0, 0, 1) 0s, transform 0.25s cubic-bezier(0, 0, 0, 1) 0.25s; }
    .menu-icon.-nav-open .middle:after {
      bottom: 0;
      transform: rotate(45deg);
      transition: bottom 0.25s cubic-bezier(0, 0, 0, 1) 0s, transform 0.25s cubic-bezier(0, 0, 0, 1) 0.25s; }

.site-footer {
  padding: 0 0 60px 0;
  background-color: #151515; }
  @media all and (max-width: 1300px) {
    .site-footer {
      text-align: center; } }
  .site-footer p {
    color: #8f8f8f;
    margin: 0; }
  @media all and (min-width: 1301px) {
    .site-footer .site-footer-logos {
      display: flex;
      align-items: center; } }
  @media all and (max-width: 1300px) {
    .site-footer .site-footer-logos {
      margin: 30px 0 15px; } }
  @media all and (max-width: 1300px) {
    .site-footer .site-footer-links {
      margin-top: 20px; } }

.site-footer-inner {
  padding-bottom: 10px; }
  @media all and (min-width: 1301px) {
    .site-footer-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; } }
  .site-footer-inner.-border-top {
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }

.site-footer-logo {
  margin: 0; }

.site-footer-links {
  list-style: none;
  margin: 0;
  padding: 0; }
  .site-footer-links li {
    display: inline-block;
    color: rgba(255, 255, 255, 0.45);
    padding: 12px 0;
    margin-left: 15px; }
    @media all and (max-width: 1300px) {
      .site-footer-links li {
        margin-left: 7px;
        margin-right: 7px; } }
    .site-footer-links li a {
      color: inherit;
      transition: color 0.25s ease; }
      .site-footer-links li a:hover {
        color: #fff;
        text-decoration: underline; }

.social-links {
  display: flex;
  align-items: center;
  padding-top: 20px; }
  @media all and (max-width: 1300px) {
    .social-links {
      justify-content: center; } }
  .social-links a {
    display: block;
    font-size: 0;
    margin: 0 20px 0 0; }
    @media all and (max-width: 1300px) {
      .social-links a {
        margin: 0 10px; } }
    .social-links a > svg {
      fill: rgba(255, 255, 255, 0.4);
      height: 20px;
      width: auto;
      transition: fill 0.25s ease; }
    .social-links a > img {
      height: 20px;
      width: auto; }
    .social-links a:hover > svg {
      fill: #fff; }

.site-footer-logos-listing {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media all and (max-width: 1300px) {
    .site-footer-logos-listing {
      justify-content: center; } }
  @media all and (max-width: 768px) {
    .site-footer-logos-listing {
      gap: 0 24px; } }
  .site-footer-logos-listing > a,
  .site-footer-logos-listing > span {
    position: relative;
    margin: 7px 0; }
    .site-footer-logos-listing > a:not(:last-child),
    .site-footer-logos-listing > span:not(:last-child) {
      margin-right: 49px; }
      @media all and (max-width: 768px) {
        .site-footer-logos-listing > a:not(:last-child),
        .site-footer-logos-listing > span:not(:last-child) {
          margin-right: 0; } }
      .site-footer-logos-listing > a:not(:last-child)::after,
      .site-footer-logos-listing > span:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -24px;
        width: 1px;
        height: 24px;
        background-color: #fff;
        transform: translateY(-50%); }
        @media all and (max-width: 768px) {
          .site-footer-logos-listing > a:not(:last-child)::after,
          .site-footer-logos-listing > span:not(:last-child)::after {
            content: none; } }

@media all and (max-width: 1300px) {
  .primary-nav {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translateY(-50px);
    transition: transform 0.25s cubic-bezier(0, 0, 0.125, 1.06);
    pointer-events: none;
    background-color: #2C2C2C;
    padding: 50px 32px 85px;
    height: calc(100vh - 50px);
    z-index: 1;
    overflow: auto; }
    .site-header.-nav-open .primary-nav {
      transform: translateY(100%) translateY(1px);
      pointer-events: auto; } }

.primary-nav > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0; }
  @media all and (max-width: 1300px) {
    .primary-nav > ul {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 22px; } }
  .primary-nav > ul > li {
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    transition: color 0.3s ease; }
    @media all and (min-width: 1301px) {
      .primary-nav > ul > li {
        font-size: 10px;
        padding: 19px 0;
        margin: 0 18px; }
        .primary-nav > ul > li:hover {
          color: #fff; }
        .site-header.site-header--light .primary-nav > ul > li {
          color: rgba(0, 0, 0, 0.7); }
          .site-header.site-header--light .primary-nav > ul > li:hover {
            color: #000; } }
    @media all and (min-width: 981px) {
      .primary-nav > ul > li {
        font-size: 12px; } }
    @media all and (max-width: 1300px) {
      .primary-nav > ul > li {
        display: block;
        text-transform: none;
        color: #fff;
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 30px; } }
    .primary-nav > ul > li a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      margin: 0; }
    @media all and (min-width: 1301px) {
      .primary-nav > ul > li.has-children a {
        margin-right: 5px; }
      .primary-nav > ul > li.has-children:after {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px;
        width: 11px;
        height: 11px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        border-left: 1px solid rgba(255, 255, 255, 0.7);
        transform: rotate(-45deg);
        transform-origin: 30% 70%;
        transition: border-color 0.25s cubic-bezier(0, 0, 0.125, 1.06), transform 0.3s ease, top 0.3s ease; }
        .site-header.site-header--light .primary-nav > ul > li.has-children:after {
          border-color: rgba(0, 0, 0, 0.7); }
      .primary-nav > ul > li.has-children:hover:after, .primary-nav > ul > li.has-children:focus-within[aria-haspopup="true"]:after {
        transform: rotate(135deg); }
      .primary-nav > ul > li.has-children:hover .sub-menu, .primary-nav > ul > li.has-children:focus-within[aria-haspopup="true"] .sub-menu {
        opacity: 1;
        visibility: visible; } }
    @media all and (max-width: 1300px) {
      .primary-nav > ul > li.has-children > a {
        position: relative;
        display: block;
        padding-right: 40px;
        margin: 0; }
        .primary-nav > ul > li.has-children > a .sub-menu-toggle {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 0;
          right: 0; }
          .primary-nav > ul > li.has-children > a .sub-menu-toggle:before, .primary-nav > ul > li.has-children > a .sub-menu-toggle:after {
            content: '';
            background-color: #fff;
            position: absolute; }
          .primary-nav > ul > li.has-children > a .sub-menu-toggle:before {
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1px; }
          .primary-nav > ul > li.has-children > a .sub-menu-toggle:after {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 100%;
            transition: transform 0.25s cubic-bezier(0, 0, 0.125, 1.06); }
        .primary-nav > ul > li.has-children > a + .sub-menu {
          display: none;
          position: relative;
          opacity: 1;
          visibility: visible;
          transform: none;
          padding: 24px 16px 0;
          background-color: transparent; }
          .primary-nav > ul > li.has-children > a + .sub-menu ul li {
            text-transform: uppercase;
            font-size: 10px;
            line-height: 10px;
            background: none !important;
            margin-bottom: 20px; }
            .primary-nav > ul > li.has-children > a + .sub-menu ul li img {
              display: none; }
            .primary-nav > ul > li.has-children > a + .sub-menu ul li .overlay-link {
              position: static;
              height: auto;
              font-size: inherit; }
            .primary-nav > ul > li.has-children > a + .sub-menu ul li .readMore {
              display: none; }
          .primary-nav > ul > li.has-children > a + .sub-menu.-show-sub-menu .sub-menu-toggle:after {
            transform: translateX(-50%) rotate(90deg); } }
  @media all and (min-width: 1301px) {
    .primary-nav > ul .sub-menu {
      left: 0;
      right: 0;
      top: 100%;
      bottom: auto;
      transform: none; }
      .primary-nav > ul .sub-menu > ul {
        width: 100%;
        max-width: 1368px;
        margin: 0 auto;
        display: flex;
        justify-content: center; }
        .primary-nav > ul .sub-menu > ul > li {
          position: relative;
          width: 25%;
          padding-top: 32.75%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          text-align: center; } }
      @media all and (min-width: 1301px) and (max-width: 980px) {
        .primary-nav > ul .sub-menu > ul > li {
          width: 50%;
          padding-top: 50%; } }
      @media all and (min-width: 1301px) and (max-width: 768px) {
        .primary-nav > ul .sub-menu > ul > li {
          padding-top: 65.5%; } }
  @media all and (min-width: 1301px) {
          .primary-nav > ul .sub-menu > ul > li::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            pointer-events: none;
            transition: background-color 0.5s cubic-bezier(0, 0, 0.125, 1.06); }
          .primary-nav > ul .sub-menu > ul > li img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } }
        @media all and (min-width: 1301px) and (max-width: 768px) {
          .primary-nav > ul .sub-menu > ul > li img {
            max-width: 57%; } }
  @media all and (min-width: 1301px) {
          .primary-nav > ul .sub-menu > ul > li .readMore {
            bottom: 60px;
            z-index: 1; } }
      @media all and (min-width: 1301px) and (prefers-reduced-motion: no-preference) {
        .primary-nav > ul .sub-menu > ul > li:hover::before {
          background-color: #84754E; } }

.primary-nav .site-footer-links {
  margin-bottom: 8px; }
  .primary-nav .site-footer-links li {
    display: block;
    margin-left: 0;
    margin-bottom: 15px; }

.primary-nav .social-links {
  padding-top: 18px;
  justify-content: flex-start; }
  .primary-nav .social-links a {
    margin: 0 20px 0 0; }

.primary-nav .site-footer-logos {
  margin-top: 30px; }
  .primary-nav .site-footer-logos p {
    margin: 0 0 7px; }

.primary-nav .site-footer-logos-listing {
  justify-content: flex-start; }

@media all and (min-width: 1301px) {
  .mobile-nav-only {
    display: none; } }

.imgZoom {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: 0.2s cubic-bezier(0, 0, 0, 1); }
  .imgZoom:hover {
    transform: scale(1.1); }

.img:hover .imgZoom {
  transform: scale(1.1); }

.accordion {
  display: block;
  margin-top: 16px;
  /* for rich-text spacing purposes */ }
  .accordion + *:not(.accordion) {
    margin-top: 40px; }
  .accordion.active > .accordion-heading {
    background-color: #323232;
    color: #fff; }
    .accordion.active > .accordion-heading .toggle::before, .accordion.active > .accordion-heading .toggle::after {
      background-color: #fff; }
    .accordion.active > .accordion-heading .toggle::before {
      transform: translate(-50%, -50%) rotate(-90deg); }
    .accordion.active > .accordion-heading .toggle::after {
      transform: translate(-50%, -50%) rotate(180deg); }

.accordion-heading {
  display: block;
  position: relative;
  padding: 23px 65px 23px 30px;
  border: 1px solid #000;
  color: #000;
  font-size: 14px;
  font-size: 1.16667rem;
  line-height: 24px;
  line-height: 2rem;
  letter-spacing: 0.06em;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease; }
  @media all and (max-width: 568px) {
    .accordion-heading {
      padding: 15px 70px 15px 20px; } }
  .accordion-heading:hover {
    background-color: #F2F2F2; }
  .accordion-heading .toggle {
    position: absolute;
    width: 26px;
    height: 26px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    background-color: transparent;
    backface-visibility: hidden; }
    @media all and (max-width: 568px) {
      .accordion-heading .toggle {
        right: 22px; } }
    .accordion-heading .toggle::before, .accordion-heading .toggle::after {
      content: "";
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease, background-color 0.25s ease; }
    .accordion-heading .toggle::before {
      height: 100%;
      width: 0.05em; }
    .accordion-heading .toggle::after {
      width: 100%;
      height: 0.05em; }

.accordion-content {
  display: none;
  padding: 30px 30px 20px; }
  @media all and (max-width: 568px) {
    .accordion-content {
      padding: 30px 20px; } }
  .accordion-content > *:first-child {
    margin-top: 0; }
  .accordion-content > *:last-child {
    margin-bottom: 0; }

/* Accordions within accordions */
.accordion-content > .accordion {
  margin-top: -1px; }
  .accordion-content > .accordion:first-child {
    border-top: none; }
  .accordion-content > .accordion .accordion-heading {
    background-color: transparent !important;
    /* override parent background colour */
    font-size: 18px;
    line-height: 30px;
    color: #000;
    text-transform: none;
    padding: 16px 32px; }
    @media all and (max-width: 568px) {
      .accordion-content > .accordion .accordion-heading {
        padding: 16px 0 16px 32px; } }
    .accordion-content > .accordion .accordion-heading .toggle {
      right: auto;
      left: 0;
      width: 20px;
      height: 20px; }
      .accordion-content > .accordion .accordion-heading .toggle:before, .accordion-content > .accordion .accordion-heading .toggle:after {
        background-color: #000; }
      .accordion-content > .accordion .accordion-heading .toggle:before {
        width: 1px; }
      .accordion-content > .accordion .accordion-heading .toggle:after {
        height: 1px; }
  .accordion-content > .accordion .accordion-content {
    padding: 0 0 45px 35px; }
    @media all and (max-width: 568px) {
      .accordion-content > .accordion .accordion-content {
        padding: 0 0 20px 35px; } }

.icon {
  display: inline-block; }

.icon-instagram {
  width: 20px;
  height: 20px; }

.icon-pinterest {
  width: 15px;
  height: 20px; }

.icon-linkedin {
  width: 15px;
  height: 15px; }

.icon-close {
  width: 28px;
  height: 28px; }

.hero-area {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  height: auto;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #151515; }
  @media all and (min-width: 769px) {
    .hero-area {
      padding: 90px;
      min-height: 600px;
      max-height: 768px; }
      .hero-area:not(.contactus-hero) {
        height: 100vh; } }
  @media all and (max-width: 568px) {
    .hero-area {
      background-image: none !important; } }
  .hero-area::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: 1;
    pointer-events: none; }
  .hero-area--light:not(.has-img) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%); }
  .hero-area--light:before {
    background-color: #fff;
    opacity: 0.75; }
  .hero-area--light.contactus-hero:before {
    background-color: #fff;
    opacity: 1; }

.hero-area-bg-image,
.hero-area-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.hero-area-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media all and (min-width: 569px) {
    .hero-area-bg-image {
      display: none; } }

@media (prefers-reduced-motion) {
  .hero-area-bg-video {
    display: none; } }

@media all and (max-width: 568px) {
  .hero-area-bg-video {
    display: none; } }

.hero-area-bg-video video {
  width: 100%;
  height: auto;
  min-width: 1380px; }

.hero-area-inner {
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  padding: 120px 0px 0px; }
  @media all and (min-width: 769px) {
    .hero-area-inner {
      padding: 90px 0px 0px; } }
  .hero-area-inner h1, .hero-area-inner .h1,
  .hero-area-inner .hero-area-inner-text {
    color: #fff; }
    .hero-area.hero-area--light .hero-area-inner h1, .hero-area.hero-area--light .hero-area-inner .h1, .hero-area.hero-area--light
    .hero-area-inner .hero-area-inner-text {
      color: #000; }
  .hero-area-inner .hero-area-inner-text {
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem;
    max-width: 760px;
    margin: 0 auto; }
    @media all and (min-width: 769px) {
      .hero-area-inner .hero-area-inner-text {
        font-size: 18px;
        font-size: 1.5rem;
        line-height: 32px;
        line-height: 2.66667rem; } }
    .hero-area-inner .hero-area-inner-text p {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem; }
      @media all and (min-width: 769px) {
        .hero-area-inner .hero-area-inner-text p {
          font-size: 18px;
          font-size: 1.5rem;
          line-height: 32px;
          line-height: 2.66667rem; } }
  .hero-area-inner .button {
    margin-top: 50px; }
    @media all and (max-width: 420px) {
      .hero-area-inner .button.js-lightbox {
        display: none; } }

.hero-explore {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-items: center;
  letter-spacing: 0.86px;
  color: #fff;
  z-index: 1;
  padding-bottom: 50px; }
  @media all and (min-width: 769px) {
    .hero-explore {
      bottom: 135px; } }
  .hero-explore .wrap {
    text-align: center;
    width: 22px;
    height: 11px;
    cursor: pointer;
    margin-top: 20px; }
  .hero-explore svg {
    width: auto; }
  .hero-explore .wrap svg {
    width: 100%;
    height: 100%; }
  .hero-explore .bounce {
    animation: bounce 1.5s infinite;
    will-change: transform; }

@keyframes bounce {
  0%, 100% {
    transform: translateY(0); }
  30% {
    transform: translateY(10px); } }

.hero-area-logo {
  margin-bottom: 75px; }
  @media all and (max-width: 768px) {
    .hero-area-logo {
      display: none; } }

.lightbox {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 43px 90px;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.125, 1.06), visibility 0.3s cubic-bezier(0, 0, 0.125, 1.06); }
  .lightbox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5; }
  .lightbox.-active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

.lightbox-player {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 1280px;
  margin: 0 auto; }

.lightbox-player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%; }
  .lightbox-player-wrapper iframe, .lightbox-player-wrapper video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.lightbox-close {
  position: absolute;
  top: -18px;
  right: -42px;
  font-size: 0;
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  cursor: pointer; }
  .lightbox-close:hover {
    transform: rotate(180deg); }

.button {
  position: relative;
  display: inline-block;
  padding: 15px 40px 15px 35px;
  margin: 0;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.22em;
  border: 1px solid #fff;
  color: #fff;
  transform-origin: 0 50%;
  text-align: left;
  transition: border-color 0.6s cubic-bezier(0, 0, 0.125, 1.06); }
  @media all and (max-width: 420px) {
    .button {
      padding: 15px 35px 15px 30px; } }
  .button__flourish {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 40px;
    height: 1px;
    background-color: #fff;
    transition: transform 0.6s cubic-bezier(0, 0, 0.125, 1.06), background-color 0.6s cubic-bezier(0, 0, 0.125, 1.06); }
  .button::before, .button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    height: 100%;
    width: 100%;
    transform: scale(0);
    pointer-events: none;
    background-color: transparent;
    transition: transform 0.6s cubic-bezier(0, 0, 0.125, 1.06); }
  .button::before {
    border-right: 1px solid #84754E;
    border-top: 1px solid #84754E;
    transform-origin: 100% 0; }
  .button::after {
    border-left: 1px solid #84754E;
    border-bottom: 1px solid #84754E;
    transform-origin: 0 100%; }
  .button:hover {
    border-color: transparent; }
    .button:hover .button__flourish {
      transform: translate(70%, -50%);
      background-color: #84754E; }
    .button:hover::before, .button:hover::after {
      transform: scale(1); }

.button--black,
.button--black-on-light {
  border-color: #000;
  color: #000; }
  .button--black .button__flourish,
  .button--black-on-light .button__flourish {
    background-color: #000; }

.button--grey {
  border-color: rgba(0, 0, 0, 0.3);
  color: inherit; }
  .button--grey .button__flourish {
    background-color: rgba(0, 0, 0, 0.3); }

.button--primary-gold {
  border-color: #84754E;
  color: #84754E; }
  .button--primary-gold::before {
    border-right-color: #fff;
    border-top-color: #fff; }
  .button--primary-gold::after {
    border-left-color: #fff;
    border-bottom-color: #fff; }
  .button--primary-gold .button__flourish {
    background-color: #84754E; }
  .button--primary-gold:hover .button__flourish {
    background-color: #fff; }

.button--fill-black {
  padding: 18px 35px;
  background-color: #000;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease; }
  .button--fill-black .button__flourish {
    display: none; }
  .button--fill-black:hover {
    background-color: #323232; }

.text-link {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-size: 0.83333rem;
  line-height: 10px;
  line-height: 0.83333rem;
  letter-spacing: 0.22em;
  padding-bottom: 5px;
  color: #fff;
  transition: color 0.6s cubic-bezier(0, 0, 0.125, 1.06); }
  .text-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #fff;
    transform-origin: 50% 0;
    transition: transform 0.6s cubic-bezier(0, 0, 0.125, 1.06); }
  .text-link--primary-gold {
    color: #84754E; }
    .text-link--primary-gold::after {
      background-color: #84754E; }
    .text-link--primary-gold:hover {
      color: #fff; }
      .text-link--primary-gold:hover::after {
        transform: scaleY(3); }

.external-link {
  display: inline-flex;
  align-items: center; }
  .external-link svg {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    margin-bottom: 2px; }

.faqs-container {
  background-color: #fff;
  padding: 70px 32px;
  overflow: hidden; }
  @media all and (max-width: 568px) {
    .faqs-container {
      padding: 32px 32px 42px; } }
  .faqs-container + .rich-text-container {
    padding-top: 0; }

.faqs-container-inner {
  width: 100%;
  max-width: 745px;
  margin: 0 auto; }
  .faqs-container-inner > h2, .faqs-container-inner h3, .faqs-container-inner h4, .faqs-container-inner h5, .faqs-container-inner h6 {
    margin-bottom: 1.75em; }

/*** Global and page-specific form style ***/
fieldset {
  border: none;
  padding: 0;
  margin: 0; }
  fieldset legend {
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem;
    margin-bottom: 1em; }
    @media all and (min-width: 769px) {
      fieldset legend {
        font-size: 18px;
        font-size: 1.5rem;
        line-height: 32px;
        line-height: 2.66667rem; } }

label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.5rem;
  letter-spacing: 0.06em; }
  label.hidden {
    height: 0 !important;
    width: 0 !important;
    font-size: 0 !important;
    margin: 0 !important; }

button {
  border: none;
  background-color: transparent; }

input[type=date],
input[type=email],
input[type=file],
input[type=text],
input[type=radio],
input[type=checkbox] {
  display: block;
  width: 100%;
  padding: 8px 0 13px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 0;
  transition: border-bottom-color 0.3s ease, padding-left 0.35s ease;
  letter-spacing: 0.06em; }
  input[type=date]::-webkit-input-placeholder,
  input[type=email]::-webkit-input-placeholder,
  input[type=file]::-webkit-input-placeholder,
  input[type=text]::-webkit-input-placeholder,
  input[type=radio]::-webkit-input-placeholder,
  input[type=checkbox]::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input[type=date]:-moz-placeholder,
  input[type=email]:-moz-placeholder,
  input[type=file]:-moz-placeholder,
  input[type=text]:-moz-placeholder,
  input[type=radio]:-moz-placeholder,
  input[type=checkbox]:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.5); }
  input[type=date]::-moz-placeholder,
  input[type=email]::-moz-placeholder,
  input[type=file]::-moz-placeholder,
  input[type=text]::-moz-placeholder,
  input[type=radio]::-moz-placeholder,
  input[type=checkbox]::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.5); }
  input[type=date]:-ms-input-placeholder,
  input[type=email]:-ms-input-placeholder,
  input[type=file]:-ms-input-placeholder,
  input[type=text]:-ms-input-placeholder,
  input[type=radio]:-ms-input-placeholder,
  input[type=checkbox]:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input[type=date]:focus-within, input[type=date]:hover,
  input[type=email]:focus-within,
  input[type=email]:hover,
  input[type=file]:focus-within,
  input[type=file]:hover,
  input[type=text]:focus-within,
  input[type=text]:hover,
  input[type=radio]:focus-within,
  input[type=radio]:hover,
  input[type=checkbox]:focus-within,
  input[type=checkbox]:hover {
    outline: 0;
    border-bottom-color: #000; }
  input[type=date]:focus-within + .field-validation-error:before,
  input[type=email]:focus-within + .field-validation-error:before,
  input[type=file]:focus-within + .field-validation-error:before,
  input[type=text]:focus-within + .field-validation-error:before,
  input[type=radio]:focus-within + .field-validation-error:before,
  input[type=checkbox]:focus-within + .field-validation-error:before {
    opacity: 0; }
  input[type=date][type=radio], input[type=date][type=checkbox],
  input[type=email][type=radio],
  input[type=email][type=checkbox],
  input[type=file][type=radio],
  input[type=file][type=checkbox],
  input[type=text][type=radio],
  input[type=text][type=checkbox],
  input[type=radio][type=radio],
  input[type=radio][type=checkbox],
  input[type=checkbox][type=radio],
  input[type=checkbox][type=checkbox] {
    height: auto; }

textarea {
  width: 100%;
  padding: 15px 22px;
  margin-bottom: 30px;
  background-color: #fff;
  border: none; }
  textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.7); }
  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.7); }
  textarea:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }

.select-wrapper,
select {
  width: 100%; }

select {
  padding: 8px 30px 13px 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0;
  transition: border-bottom-color 0.3s ease;
  appearance: none; }
  select:focus {
    outline: 0; }
  select:focus-within, select:hover {
    border-bottom-color: #000; }

.select-wrapper {
  position: relative;
  background-color: transparent;
  margin-bottom: 30px; }
  .select-wrapper label {
    height: 0 !important;
    width: 0 !important;
    font-size: 0 !important;
    margin: 0 !important; }
  .select-wrapper .select-arrow {
    position: absolute;
    right: 0;
    /* for border */
    top: 4px;
    width: 26px;
    height: 26px;
    /* height of select-wrapper, minus 2 for border */
    background-color: transparent;
    pointer-events: none; }
    .select-wrapper .select-arrow::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 6px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg); }
  .select-wrapper select:hover + .select-arrow {
    transform: scaleY(-1); }

input[type=radio],
input[type=checkbox] {
  position: absolute;
  left: -9999px; }

.radio-wrapper,
.checkbox-wrapper {
  margin-bottom: 30px; }
  .radio-wrapper label,
  .checkbox-wrapper label {
    position: relative;
    padding: 5px 0 5px 26px;
    cursor: pointer; }
    .radio-wrapper label::before,
    .checkbox-wrapper label::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 6px;
      left: 0;
      background-color: transparent; }
  .radio-wrapper.inline label,
  .checkbox-wrapper.inline label {
    display: inline-block;
    margin-right: 30px; }
  .radio-wrapper + .field-validation-error::before,
  .checkbox-wrapper + .field-validation-error::before {
    opacity: 0; }

.radio-wrapper label::before {
  border-radius: 100%;
  border: 1px solid #000; }

.radio-wrapper input[type=radio]:checked + label::before,
.radio-wrapper input[type=radio]:checked + input + label::before {
  background-color: #000; }

.checkbox-wrapper label::before {
  content: '';
  font-size: 14px;
  line-height: 18px;
  box-shadow: 0 0 0 1px #606060;
  border: 2px solid #f4f4f4;
  opacity: 0.6; }

.checkbox-wrapper input[type=checkbox]:checked + label::before,
.checkbox-wrapper input[type=checkbox]:checked + input + label::before {
  content: '';
  background-color: #000;
  opacity: 1; }

/*** Umbraco Forms custom styles ***/
@media all and (min-width: 769px) {
  .umbraco-forms-fieldset > .row-fluid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .umbraco-forms-fieldset > .row-fluid .umbraco-forms-container.col-md-6 {
      width: calc(50% - 18px); } }

.umbraco-forms-container {
  width: 100%; }

.umbraco-forms-field-wrapper {
  position: relative; }

.umbraco-forms-navigation {
  margin-top: 20px; }

.field-validation-error {
  position: static;
  display: block;
  margin: -20px 0 20px;
  color: #F43F36; }
  .field-validation-error::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 2px;
    height: 20px;
    background-color: #F43F36;
    opacity: 1;
    transition: opacity 0.3s ease; }

.input-validation-error input:not(:focus-within) {
  padding-left: 10px; }

.input-validation-error select {
  padding-left: 8px; }

.umbraco-forms-submitmessage {
  display: block;
  font-size: 24px;
  font-size: 2rem;
  line-height: 40px;
  line-height: 3.33333rem;
  letter-spacing: 0.18em; }

.g-recaptcha {
  transform-origin: 0 0; }
  .g-recaptcha + .field-validation-error {
    display: none; }

@media (min-width: 768px) {
  .container .max-840-left {
    max-width: 840px; } }

@media (min-width: 1200px) {
  .container .max-840-left {
    padding: 0; } }

.container.standard-content a {
  text-decoration: underline;
  color: #000;
  font-family: 'GT-America-Expanded-Medium'; }

@media (min-width: 768px) {
  .container.standard-content p {
    max-width: 840px; } }

.container.standard-content strong {
  color: #000; }

.container.standard-content ul {
  list-style: none; }
  .container.standard-content ul li {
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.7);
    margin: .75rem 0; }
    .container.standard-content ul li:before {
      content: "\2022";
      color: rgba(0, 0, 0, 0.7);
      display: inline-block;
      width: 1em;
      margin-left: -1em; }

.container.standard-content ol {
  list-style: none;
  counter-reset: li; }
  .container.standard-content ol li {
    color: rgba(0, 0, 0, 0.7);
    counter-increment: li; }
    .container.standard-content ol li:before {
      content: counter(li);
      color: rgba(0, 0, 0, 0.7);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl; }

.container .image-caption {
  color: rgba(0, 0, 0, 0.5);
  padding-top: 1.5rem;
  justify-content: center; }
  .container .image-caption:before {
    opacity: .5; }

.container .img-caption-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 137px; }
  @media (min-width: 768px) {
    .container .img-caption-img {
      height: 268px; } }
  @media (min-width: 980px) {
    .container .img-caption-img {
      height: 440px; } }

.container .curver-border-bottom-right {
  border-radius: 0px 0px 6rem 0px; }
  @media (min-width: 980px) {
    .container .curver-border-bottom-right {
      border-radius: 0px 0px 10rem 0px; } }

.image-gallery-carousel {
  position: relative;
  width: 100%; }
  .image-gallery-carousel.product-page {
    width: calc(100% + 60px);
    margin: 0 -30px; }
    .image-gallery-carousel.product-page #image-gallery .fill {
      height: 324px; }
    @media (min-width: 768px) {
      .image-gallery-carousel.product-page {
        border-bottom-left-radius: 160px;
        overflow: hidden;
        width: 100%;
        margin: 0; }
        .image-gallery-carousel.product-page #image-gallery .fill {
          height: 440px; } }
  .image-gallery-carousel h2 {
    margin-bottom: 3rem;
    font-size: 24px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 2.66667rem; }
    .image-gallery-carousel h2:before {
      height: 43px;
      margin-right: 31px; }
    @media (min-width: 768px) {
      .image-gallery-carousel h2 {
        font-size: 32px;
        font-size: 2.66667rem;
        line-height: 40px;
        line-height: 3.33333rem; } }
  .image-gallery-carousel #image-gallery .fill {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 323px;
    position: relative; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .fill {
        height: 560px;
        position: relative; } }
  .image-gallery-carousel #image-gallery .slick-slide {
    position: relative; }
  .image-gallery-carousel #image-gallery .slider-caption {
    position: relative;
    bottom: -1.5rem;
    width: 100%;
    padding: 0 24px;
    text-align: center; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .slider-caption {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 1rem; }
        .image-gallery-carousel #image-gallery .slider-caption p {
          color: #fff; } }
  .image-gallery-carousel #image-gallery .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none; }
    .image-gallery-carousel #image-gallery .slick-arrow > svg {
      display: none; }
    .image-gallery-carousel #image-gallery .slick-arrow.slick-prev {
      left: 150px;
      z-index: 99; }
    .image-gallery-carousel #image-gallery .slick-arrow.slick-next {
      right: 86px; }
    .image-gallery-carousel #image-gallery .slick-arrow:hover {
      cursor: pointer;
      background: #000;
      transition: 0.2s cubic-bezier(0, 0, 0, 1); }
      .image-gallery-carousel #image-gallery .slick-arrow:hover > svg {
        fill: #fff; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .slick-arrow {
        background: #fff;
        border-radius: 50%;
        padding: 24px 24px;
        display: block;
        width: 64px;
        height: 64px; }
        .image-gallery-carousel #image-gallery .slick-arrow > svg {
          display: block; } }
  .image-gallery-carousel .slider-info {
    letter-spacing: .6px;
    text-align: center;
    color: #000;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    padding: 0 6rem; }
    @media (min-width: 768px) {
      .image-gallery-carousel .slider-info {
        color: #fff;
        position: absolute;
        bottom: 4rem;
        z-index: 9;
        transform: translate(-50%, -50%);
        left: 50%;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0; } }
    .image-gallery-carousel .slider-info .custom_paging {
      margin-top: .75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'GT-America-Expanded-Medium'; }
      @media (min-width: 768px) {
        .image-gallery-carousel .slider-info .custom_paging {
          margin-bottom: .75rem; } }
      .image-gallery-carousel .slider-info .custom_paging .slider-pag-first {
        margin-right: 1.5rem; }
      .image-gallery-carousel .slider-info .custom_paging .slider-pag-second {
        margin-left: 1.25rem;
        opacity: 0.5; }
      .image-gallery-carousel .slider-info .custom_paging .pad-divider {
        display: inline-flex;
        margin: 0 2rem; }
        .image-gallery-carousel .slider-info .custom_paging .pad-divider:before {
          content: '';
          width: 1px;
          height: 35px;
          background-color: #000;
          opacity: 0.5;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
          @media (min-width: 768px) {
            .image-gallery-carousel .slider-info .custom_paging .pad-divider:before {
              background-color: #fff; } }

.circular-block-wrapper {
  margin: 0; }
  .circular-block-wrapper .circular-block-item {
    margin-top: 3rem;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .circular-block-wrapper .circular-block-item {
        padding: 0 24px; } }
    .circular-block-wrapper .circular-block-item .content {
      padding: 0 0 0 24px !important;
      width: calc( 100% - 100px); }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .content {
          width: calc( 100% - 144px); } }
    .circular-block-wrapper .circular-block-item .cta {
      width: 100%;
      display: flex;
      margin-top: 32px; }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .cta {
          padding-left: 168px;
          margin-top: 0; } }
    .circular-block-wrapper .circular-block-item .img {
      height: 100px;
      border-radius: 50%;
      width: 100px;
      border: 1px solid #DBDBDB; }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .img {
          height: 144px;
          max-width: 144px;
          min-width: 144px; } }

.image-block {
  overflow: hidden; }
  .image-block img {
    max-width: 100%;
    height: auto; }

.listing-item.image-text-block {
  padding: 0; }

.listing.alternate.light .listing-item.image-text-block:after {
  content: normal; }

.contactUsBlock {
  background-color: #fff;
  padding-top: 45px;
  padding-bottom: 45px; }
  @media all and (min-width: 769px) {
    .contactUsBlock {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .contactUsBlock-inner {
    width: 100%;
    max-width: 745px;
    margin: 0 auto; }
    .contactUsBlock-inner > * {
      margin-bottom: 3rem; }
      .contactUsBlock-inner > *:last-child {
        margin-bottom: 0; }
      .contactUsBlock-inner > * h4 {
        margin-bottom: 1rem; }

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0; }
  @media (min-width: 768px) {
    .listing-header {
      padding: 32px 0; } }
  .listing-header .sub-heading {
    display: none;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .listing-header .sub-heading {
        display: flex; } }
  .listing-header .filter-btn {
    font-size: 10px;
    font-size: 0.83333rem;
    line-height: 16px;
    line-height: 1.33333rem;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: black;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer; }
    .listing-header .filter-btn svg {
      margin-left: 30px; }
    @media (min-width: 768px) {
      .listing-header .filter-btn {
        border: 1px solid black;
        padding: 16px 32px; }
        .listing-header .filter-btn:hover {
          color: white;
          background: black; } }

.listing.product-list {
  margin: 0; }
  .listing.product-list .product {
    margin: 12px 10px;
    max-width: 100%; }
    @media (min-width: 580px) {
      .listing.product-list .product {
        max-width: calc(50% - 20px); } }
    .listing.product-list .product a {
      width: 100%;
      margin-bottom: 0; }
    .listing.product-list .product .img {
      border: 1px solid #e8e8e8;
      width: 100%;
      height: 320px; }
    .listing.product-list .product .content {
      padding: 24px 8px 12px;
      text-align: center; }
      .listing.product-list .product .content .prod-name {
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .6px;
        color: black; }
      .listing.product-list .product .content .category {
        color: black;
        opacity: .7;
        letter-spacing: .6px; }
  @media (min-width: 768px) {
    .listing.product-list .product {
      border: 1px solid #e8e8e8;
      max-width: calc(33.333333% - 20px) !important;
      margin-left: 10px;
      margin-right: 10px; }
      .listing.product-list .product:last-child {
        margin-left: 10px; }
      .listing.product-list .product:nth-child(3n) {
        margin-right: 0px; }
      .listing.product-list .product .img {
        border: 0; } }
  @media (min-width: 980px) {
    .listing.product-list .product .img {
      height: 300px; }
    .listing.product-list .product .content .prod-name {
      font-size: 18px;
      font-size: 1.5rem;
      line-height: 24px;
      line-height: 2rem; } }

.no-results h2 {
  margin-bottom: 16px; }

.no-results .button {
  display: inline-flex; }

.download-group {
  margin-top: 32px; }

.downloads-header {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .downloads-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .downloads-header.no-cta {
        justify-content: flex-end; } }

.download-links.listing {
  flex-direction: column;
  align-items: center; }
  .download-links.listing .listing-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #D8D8D8;
    width: calc(100% - 40px);
    align-items: center; }
    .download-links.listing .listing-item:nth-child(1) {
      border-top: 1px solid #D8D8D8; }
    .download-links.listing .listing-item .download-item {
      margin: 10px 48px 10px 0;
      align-self: center; }
  @media (min-width: 768px) {
    .download-links.listing {
      flex-direction: row; }
      .download-links.listing .listing-item {
        flex-direction: row;
        width: calc(50% - 10px);
        margin: 0; }
        .download-links.listing .listing-item:nth-child(2n) {
          margin: 0 0 0 20px;
          padding: 15px 20px; }
        .download-links.listing .listing-item:nth-child(-n+2) {
          border-top: 1px solid #D8D8D8; } }

.download-all {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px; }
  .download-all .button {
    align-self: flex-end;
    margin-right: 66px; }
  @media (min-width: 768px) {
    .download-all {
      justify-content: flex-end; } }

.rich-text-container {
  background-color: #fff;
  padding: 70px 32px;
  overflow: hidden; }
  @media all and (max-width: 568px) {
    .rich-text-container {
      padding: 25px 32px; } }
  .rich-text-container + .faqs-container {
    padding-top: 0; }
  .rich-text-container .cookiebot-declaration {
    width: 100%;
    max-width: 745px;
    margin: 0 auto;
    margin: 32px auto; }

.rich-text {
  width: 100%;
  max-width: 745px;
  margin: 0 auto; }
  .rich-text h1 + img, .rich-text h2 + img, .rich-text h3 + img, .rich-text h4 + img, .rich-text h5 + img, .rich-text h6 + img, .rich-text ul + img, .rich-text ol + img, .rich-text table + img, .rich-text p + img {
    margin-top: 50px; }
  .rich-text h1, .rich-text h2, .rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6 {
    margin-top: 48px; }
    .rich-text h1:first-child, .rich-text h2:first-child, .rich-text h3:first-child, .rich-text h4:first-child, .rich-text h5:first-child, .rich-text h6:first-child {
      position: relative; }
      .rich-text h1:first-child:before, .rich-text h2:first-child:before, .rich-text h3:first-child:before, .rich-text h4:first-child:before, .rich-text h5:first-child:before, .rich-text h6:first-child:before {
        content: '';
        position: absolute;
        top: -15px;
        left: 0;
        width: 40px;
        height: 1px;
        background-color: #84754E; }
  .rich-text p a, .rich-text li a {
    text-decoration: underline;
    font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
    color: inherit;
    transition: color 0.2s ease-in; }
    .rich-text p a:hover, .rich-text li a:hover {
      color: #84754E; }
  .rich-text table {
    width: 100%;
    display: block;
    overflow: auto;
    overflow-y: hidden;
    overflow-x: auto;
    border-collapse: collapse;
    margin: 30px 0;
    height: auto !important; }
    .rich-text table th, .rich-text table td {
      padding: 12px 15px;
      letter-spacing: 0.06em;
      min-width: 185px;
      height: auto !important; }
      @media all and (max-width: 568px) {
        .rich-text table th:first-child, .rich-text table td:first-child {
          padding-top: 16px; }
        .rich-text table th:not(:last-child), .rich-text table td:not(:last-child) {
          padding-bottom: 0; }
        .rich-text table th:last-child, .rich-text table td:last-child {
          padding-bottom: 16px; } }
    .rich-text table tr {
      height: auto !important; }
    @media all and (max-width: 568px) {
      .rich-text table thead {
        display: none; } }
    .rich-text table thead th {
      text-align: left;
      background-color: #252525;
      font-weight: bold;
      color: #fff;
      font-size: 14px;
      font-size: 1.16667rem;
      line-height: 20px;
      line-height: 1.66667rem; }
    @media all and (max-width: 568px) {
      .rich-text table tbody tr {
        display: block; } }
    .rich-text table tbody tr:nth-child(odd) td {
      background-color: #FCFCFC; }
    .rich-text table tbody tr:nth-child(even) td {
      background-color: #F2F2F2; }
    @media all and (max-width: 568px) {
      .rich-text table tbody {
        display: block; }
        .rich-text table tbody td {
          display: block;
          width: 100%; } }
  .rich-text ul, .rich-text ol {
    list-style-type: none;
    margin-bottom: 2em;
    padding: 0; }
  .rich-text li {
    position: relative;
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: 0.06em; }
    .rich-text li:not(:last-child) {
      padding-bottom: 1em; }
  .rich-text ul li {
    padding-left: 28px; }
    .rich-text ul li:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      width: 6px;
      height: 6px;
      background-color: #84754E;
      border-radius: 100%; }
  .rich-text ol {
    counter-reset: count; }
    .rich-text ol li {
      padding-left: 28px;
      counter-increment: count; }
      .rich-text ol li:before {
        content: counter(count) ".";
        position: absolute;
        top: 1px;
        left: 0;
        color: #84754E;
        font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif; }
  .rich-text blockquote {
    position: relative; }
    .rich-text blockquote:before, .rich-text blockquote:after {
      content: '\0201C';
      position: absolute;
      font-style: normal;
      top: 0;
      left: 0; }
    .rich-text blockquote:after {
      content: '\0201D'; }
    .rich-text blockquote:after {
      top: auto;
      left: auto;
      bottom: 0;
      right: 0; }
  .rich-text img {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    margin: 20px auto;
    left: 50%;
    transform: translateX(-50%); }
  .rich-text > *:last-child {
    margin-bottom: 0; }
    .rich-text > *:last-child img {
      margin-bottom: -70px; }
      @media all and (max-width: 568px) {
        .rich-text > *:last-child img {
          margin-bottom: -45px; } }

.video-player {
  position: relative;
  padding-top: 56.25%; }
  .video-player iframe, .video-player video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

#CybotCookiebotDialog {
  font-family: GT-America-Extended-Light !important;
  font-size: 14px !important; }

@media (min-width: 1200px) {
  .CybotCookiebotDialogContentWrapper {
    width: 1200px;
    max-width: 1200px !important; } }

.CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeader {
  display: none !important; }

#CybotCookiebotDialogBodyButtonAccept.CybotCookiebotDialogBodyButton {
  background-color: #000000 !important;
  border-color: #000000 !important; }

#CybotCookiebotDialogBodyButtonDecline.CybotCookiebotDialogBodyButton {
  border: 1px solid #000000 !important; }

#CybotCookiebotDialogBodyEdgeMoreDetails #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  color: #000000 !important; }

.content-listing-container {
  display: flex;
  flex-direction: column; }
  .content-listing-container h2 {
    text-align: center; }
  .content-listing-container .content-listing-wrapper {
    display: flex;
    flex-direction: column; }
    .content-listing-container .content-listing-wrapper .content-listing {
      position: relative;
      margin-bottom: 30px;
      height: 243px; }
      .content-listing-container .content-listing-wrapper .content-listing .img {
        height: 100%;
        overflow: hidden; }
        .content-listing-container .content-listing-wrapper .content-listing .img:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1; }
      .content-listing-container .content-listing-wrapper .content-listing .content-listing-image {
        height: 100%;
        z-index: 0; }
      .content-listing-container .content-listing-wrapper .content-listing .content-listing-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 2; }
        .content-listing-container .content-listing-wrapper .content-listing .content-listing-text a {
          color: #fff; }
          .content-listing-container .content-listing-wrapper .content-listing .content-listing-text a.small {
            text-transform: uppercase;
            letter-spacing: 1.76px;
            font-size: 11px;
            font-size: 0.91667rem;
            line-height: 19px;
            line-height: 1.58333rem; }

@media (min-width: 768px) {
  .content-listing-container .content-listing-wrapper {
    flex-direction: row; }
    .content-listing-container .content-listing-wrapper .content-listing {
      flex: 1;
      margin-left: 12px;
      height: 382px;
      margin-bottom: 0; }
      .content-listing-container .content-listing-wrapper .content-listing:first-of-type {
        margin-left: 0;
        margin-right: 12px; } }

.product-info-pod {
  padding: 45px 0 65px;
  background-color: #1C1C1C;
  overflow: hidden; }
  @media all and (min-width: 769px) {
    .product-info-pod {
      padding: 95px 0 125px; } }
  @media all and (max-width: 568px) {
    .product-info-pod {
      padding-bottom: 35px; } }
  .product-info-pod.-flip {
    background-color: #151515; }
    @media all and (min-width: 769px) {
      .product-info-pod.-flip .product-info-pod__inner {
        flex-direction: row-reverse; } }
    @media all and (min-width: 769px) {
      .product-info-pod.-flip .product-info-pod__content-col {
        padding-right: 0;
        padding-left: 105px; } }
    @media all and (min-width: 769px) {
      .product-info-pod.-flip .product-info-pod__image-col,
      .product-info-pod.-flip .product-info-pod__gallery-col {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end; } }
  .product-info-pod--white-bg {
    background-color: #fff; }
    @media all and (min-width: 769px) {
      .product-info-pod--white-bg h2 {
        margin-top: 5rem; } }
    .product-info-pod--white-bg + .partner-brands-listing .partner-brands-listing__heading {
      padding-top: 0; }
  .product-info-pod__gallery {
    position: relative; }
    @media all and (min-width: 769px) {
      .product-info-pod__gallery {
        width: calc(100% + 32px);
        max-width: 672px; } }
    @media all and (min-width: 1201px) {
      .product-info-pod__gallery {
        width: 50vw; } }
    .product-info-pod__gallery img {
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: bottom; }
    .product-info-pod__gallery picture {
      display: block; }
  .product-info-pod__galleryArrows {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    padding: 0 32px; }
    @media all and (min-width: 981px) {
      .product-info-pod__galleryArrows {
        padding: 0 48px; } }
    @media all and (min-width: 1201px) {
      .product-info-pod__galleryArrows {
        padding: 0 64px; } }
    .product-info-pod__galleryArrows .slick-arrow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      padding: 0;
      margin: 0;
      pointer-events: auto; }
      .product-info-pod__galleryArrows .slick-arrow::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #F2F2F2;
        border-radius: 100%;
        transition: background-color 0.3s ease, transform 0.3s ease; }
      .product-info-pod__galleryArrows .slick-arrow::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 10px;
        height: 10px;
        top: 24px;
        font-size: 0;
        border-left: 1px solid #151515;
        border-bottom: 1px solid #151515; }
      .product-info-pod__galleryArrows .slick-arrow.slick-prev::after {
        left: 26px;
        transform: rotate(45deg); }
      .product-info-pod__galleryArrows .slick-arrow.slick-next::after {
        left: 22px;
        transform: rotate(225deg); }
      .product-info-pod__galleryArrows .slick-arrow:hover::before {
        transform: translate(-50%, -50%) scale(1.14);
        background-color: #f4f4f4; }
  .product-info-pod__galleryDots {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px; }
    @media all and (min-width: 769px) {
      .product-info-pod__galleryDots {
        position: absolute;
        left: 0;
        bottom: -45px; } }
    .product-info-pod__galleryDots .slick-dots {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 0; }
      .product-info-pod__galleryDots .slick-dots li {
        display: inline-block;
        margin: 0 1px; }
        .product-info-pod__galleryDots .slick-dots li button {
          padding: 8px; }
          .product-info-pod__galleryDots .slick-dots li button::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 100%;
            opacity: 0.5;
            transition: background-color 0.3s ease; }
        .product-info-pod__galleryDots .slick-dots li.slick-active button::before, .product-info-pod__galleryDots .slick-dots li:hover button::before {
          background-color: #fff;
          opacity: 1; }

.product-info-pod__inner {
  display: flex;
  align-items: flex-start; }
  @media all and (max-width: 768px) {
    .product-info-pod__inner {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0; } }

.product-info-pod__content-col,
.product-info-pod__image-col {
  width: 100%; }
  @media all and (min-width: 769px) {
    .product-info-pod__content-col,
    .product-info-pod__image-col {
      width: 50%; } }

.product-info-pod__gallery-col {
  width: 100%; }
  @media all and (min-width: 769px) {
    .product-info-pod__gallery-col {
      width: 50%;
      max-width: calc(100% - 420px); } }

.product-info-pod__content-col {
  max-width: 570px; }
  .product-info-pod__content-col > *:last-child {
    margin-bottom: 0; }
  @media all and (min-width: 769px) {
    .product-info-pod__content-col {
      min-width: 420px;
      max-width: none;
      padding-right: 105px; }
      .product-info-pod.product-info-pod--white-bg .product-info-pod__content-col + .product-info-pod__content-col {
        padding-top: 145px; } }
  @media all and (max-width: 768px) {
    .product-info-pod__content-col {
      padding-left: 32px;
      padding-right: 32px; }
      .product-info-pod.product-info-pod--white-bg .product-info-pod__content-col + .product-info-pod__content-col {
        padding-top: 30px;
        padding-left: 60px; } }
  .product-info-pod__content-col p {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem; }
    .product-info-pod.product-info-pod--white-bg .product-info-pod__content-col p {
      color: #000; }
    .product-info-pod__content-col p a {
      text-decoration: underline;
      font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
      color: inherit;
      transition: color 0.2s ease-in; }
      .product-info-pod__content-col p a:hover {
        color: #84754E; }
  .product-info-pod__content-col .button {
    margin: 2em 50px 2em 0; }

.product-info-pod__logo {
  display: block;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .product-info-pod__logo img {
    max-height: 62px;
    width: auto; }

.product-info-pod__label {
  position: relative;
  display: block;
  padding: 20px 0 20px 40px;
  font-size: 10px;
  font-size: 0.83333rem;
  line-height: 16px;
  line-height: 1.33333rem;
  font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
  letter-spacing: 0.64em;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.4; }
  .product-info-pod__label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 1px;
    background-color: #fff; }
  .product-info-pod.product-info-pod--white-bg .product-info-pod__label {
    color: #84754E;
    opacity: 1; }
    .product-info-pod.product-info-pod--white-bg .product-info-pod__label:before {
      background-color: #84754E; }

.product-info-pod__sub-label {
  display: block;
  font-size: 24px;
  font-size: 2rem;
  line-height: 24px;
  line-height: 2rem;
  letter-spacing: 0.02em; }

.product-info-pod__heading {
  display: block;
  font-size: 32px;
  font-size: 2.66667rem;
  line-height: 48px;
  line-height: 4rem;
  font-family: "GT-America-Extended-Light", Helvetica, sans-serif;
  text-transform: none;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1.5rem; }
  @media all and (min-width: 569px) {
    .product-info-pod__heading {
      font-size: 40px;
      font-size: 3.33333rem;
      line-height: 56px;
      line-height: 4.66667rem;
      letter-spacing: 1px; } }
  .product-info-pod.product-info-pod--white-bg .product-info-pod__heading {
    color: #000; }
  @media all and (min-width: 569px) {
    .product-info-pod.product-info-pod--find-showroom .product-info-pod__heading {
      font-size: 32px;
      font-size: 2.66667rem;
      line-height: 48px;
      line-height: 4rem;
      letter-spacing: 0.02em; } }

.product-info-pod__image-col
.product-info-pod__gallery-col {
  padding-top: 57px; }
  @media all and (max-width: 768px) {
    .product-info-pod__image-col
.product-info-pod__gallery-col {
      padding-top: 0;
      text-align: right; }
      .product-info-pod:not(.product-info-pod.product-info-pod--white-bg) .product-info-pod__image-col
.product-info-pod__gallery-col {
        order: -1;
        margin-top: -45px;
        margin-bottom: 58px; }
      .product-info-pod.product-info-pod.product-info-pod--white-bg .product-info-pod__image-col
.product-info-pod__gallery-col {
        margin-top: 40px; }
      .product-info-pod__image-col
.product-info-pod__gallery-col img {
        width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: bottom; } }
  @media all and (min-width: 769px) {
    .product-info-pod.product-info-pod--find-showroom .product-info-pod__image-col
.product-info-pod__gallery-col {
      padding-top: 17px; } }

.contact-form {
  position: relative;
  width: 100%;
  background-color: #1C1C1C;
  padding-bottom: 60px; }

.contact-form-header {
  width: 100%;
  background-color: #fff;
  padding: 30px 0 100px; }
  .contact-form-header .product-info-pod__label {
    color: #84754E;
    opacity: 1;
    font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif; }
    .contact-form-header .product-info-pod__label::before {
      background-color: #84754E; }
  .contact-form-header h1, .contact-form-header h2, .contact-form-header h3, .contact-form-header h4, .contact-form-header p {
    font-size: 24px;
    font-size: 2rem;
    line-height: 40px;
    line-height: 3.33333rem;
    text-transform: uppercase;
    font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
    letter-spacing: 0.18em;
    margin-bottom: 16px; }

.contact-form-wrapper {
  background-color: #f4f4f4;
  padding: 45px 90px;
  margin-top: -64px; }
  @media all and (max-width: 980px) {
    .contact-form-wrapper {
      padding: 45px; } }
  @media all and (max-width: 568px) {
    .contact-form-wrapper {
      padding: 26px; } }
  .contact-form-wrapper .select-wrapper select,
  .contact-form-wrapper .select-wrapper .select-arrow {
    background-color: #f4f4f4; }

.newsletter-pod {
  padding: 80px 0;
  background-color: #151515;
  color: #fff; }
  .newsletter-pod h2, .newsletter-pod h3, .newsletter-pod h4 {
    color: #fff; }
  @media all and (max-width: 768px) {
    .newsletter-pod {
      padding: 40px 0; } }

@media all and (min-width: 769px) {
  .newsletter-pod-inner {
    display: flex;
    flex-wrap: wrap; } }

@media all and (min-width: 769px) {
  .newsletter-pod__content {
    width: 40%; } }

.newsletter-pod__content p {
  color: rgba(255, 255, 255, 0.7); }

.newsletter-pod__form {
  margin-top: 30px; }
  @media all and (min-width: 769px) {
    .newsletter-pod__form {
      width: 60%;
      margin-top: 30px;
      padding-left: 60px; } }
  .newsletter-pod__form label {
    opacity: 0.6; }
  .newsletter-pod__form input {
    border-bottom-color: rgba(255, 255, 255, 0.3); }
    .newsletter-pod__form input::placeholder {
      color: rgba(255, 255, 255, 0.3); }
    .newsletter-pod__form input:focus-within, .newsletter-pod__form input:hover {
      border-bottom-color: #fff; }
  .newsletter-pod__form .checkbox-wrapper label::before {
    border-color: #151515;
    box-shadow: 0 0 0 1px #fff; }
  .newsletter-pod__form .checkbox-wrapper input[type=checkbox]:checked + label,
  .newsletter-pod__form .checkbox-wrapper input[type=checkbox]:checked + input + label {
    opacity: 1; }
    .newsletter-pod__form .checkbox-wrapper input[type=checkbox]:checked + label::before,
    .newsletter-pod__form .checkbox-wrapper input[type=checkbox]:checked + input + label::before {
      background-color: #fff; }
  .newsletter-pod__form .button--grey {
    border-color: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.7); }
    .newsletter-pod__form .button--grey .button__flourish {
      background-color: rgba(255, 255, 255, 0.3); }
    .newsletter-pod__form .button--grey:hover .button__flourish {
      background-color: #84754E; }

.newsletter-pod__title {
  display: block;
  margin: 0.75rem 0 1.5rem;
  font-size: 24px;
  font-size: 2rem;
  line-height: 40px;
  line-height: 3.33333rem; }

.partner-brands-listing__heading {
  padding: 40px;
  background-color: #fff;
  text-align: center; }
  .partner-brands-listing__heading h1, .partner-brands-listing__heading h2, .partner-brands-listing__heading h3, .partner-brands-listing__heading h4, .partner-brands-listing__heading span {
    font-size: 32px;
    font-size: 2.66667rem;
    line-height: 48px;
    line-height: 4rem;
    font-family: "GT-America-Extended-Light", Helvetica, sans-serif;
    letter-spacing: normal;
    text-transform: none;
    margin: 0; }

.partner-brands-listing__inner {
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; }

.partner-brands-listing__item {
  position: relative;
  width: 25%;
  padding-top: 32.75%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center; }
  @media all and (max-width: 980px) {
    .partner-brands-listing__item {
      width: 50%;
      padding-top: 50%; } }
  @media all and (max-width: 768px) {
    .partner-brands-listing__item {
      padding-top: 65.5%; } }
  .partner-brands-listing__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    transition: background-color 0.5s cubic-bezier(0, 0, 0.125, 1.06); }
  .partner-brands-listing__item img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media all and (max-width: 768px) {
      .partner-brands-listing__item img {
        max-width: 57%; } }
  .partner-brands-listing__item p {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.22em;
    margin: 0;
    padding: 0 15px;
    color: #fff;
    text-transform: uppercase;
    bottom: 17%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.125, 1.06); }
    @media all and (max-width: 568px) {
      .partner-brands-listing__item p {
        font-size: 8px;
        opacity: 1; } }
    .partner-brands-listing__item p span {
      flex-shrink: 0; }
    .partner-brands-listing__item p::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      margin-left: 16px;
      background-color: #fff;
      transition: width 0.3s cubic-bezier(0, 0, 0.125, 1.06); }
      @media all and (max-width: 568px) {
        .partner-brands-listing__item p::after {
          display: none; } }
  @media (prefers-reduced-motion: no-preference) {
    .partner-brands-listing__item:hover::before {
      background-color: #84754E; }
    .partner-brands-listing__item:hover p {
      opacity: 1; }
      .partner-brands-listing__item:hover p::after {
        width: 40px; } }

.two-column-block {
  padding: 45px 0 65px;
  background-color: #1C1C1C;
  overflow: hidden; }
  @media all and (min-width: 769px) {
    .two-column-block {
      padding: 95px 0 105px; } }
  @media all and (max-width: 568px) {
    .two-column-block {
      padding-bottom: 35px; } }
  .two-column-block.-bg-black {
    background-color: #151515; }
  .two-column-block.-flip {
    background-color: #151515; }
    @media all and (min-width: 769px) {
      .two-column-block.-flip .two-column-block__inner {
        flex-direction: row-reverse; } }
    @media all and (min-width: 769px) {
      .two-column-block.-flip .two-column-block__content-col {
        padding-right: 0;
        padding-left: 105px; }
        .two-column-block.-flip .two-column-block__content-col + .two-column-block__content-col {
          order: -1; } }
    @media all and (min-width: 769px) {
      .two-column-block.-flip .two-column-block__image-col {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end; } }
    @media all and (max-width: 768px) {
      .two-column-block.-flip .two-column-block__image-col {
        text-align: left;
        padding-left: 0; } }
  .two-column-block--white-bg {
    background-color: #fff; }
  .two-column-block h1, .two-column-block h2, .two-column-block h3, .two-column-block h4 {
    text-transform: none;
    color: #fff;
    letter-spacing: 0.02em;
    font-family: "GT-America-Extended-Light", Helvetica, sans-serif; }

.two-column-block__inner {
  display: flex; }
  @media all and (max-width: 768px) {
    .two-column-block__inner {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0; } }

.two-column-block__content-col,
.two-column-block__image-col,
.two-column-block__video-col {
  width: 100%; }
  @media all and (min-width: 769px) {
    .two-column-block__content-col,
    .two-column-block__image-col,
    .two-column-block__video-col {
      width: 50%; } }

.two-column-block__content-col {
  max-width: 570px; }
  .two-column-block__content-col > *:last-child {
    margin-bottom: 0; }
  @media all and (min-width: 769px) {
    .two-column-block__content-col {
      max-width: none;
      padding-right: 105px; } }
  @media all and (min-width: 821px) {
    .two-column-block__content-col {
      min-width: 420px; } }
  @media all and (max-width: 768px) {
    .two-column-block__content-col {
      padding-left: 32px;
      padding-right: 32px;
      max-width: none; }
      .two-column-block__content-col + .two-column-block__content-col {
        margin-top: 40px; } }
  .two-column-block__content-col p {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem; }
    .two-column-block.two-column-block--white-bg .two-column-block__content-col p {
      color: #000; }
    .two-column-block__content-col p a {
      text-decoration: underline;
      font-family: "GT-America-Expanded-Medium", Helvetica, sans-serif;
      color: inherit;
      transition: color 0.2s ease-in; }
      .two-column-block__content-col p a:hover {
        color: #84754E; }
  .two-column-block__content-col .button {
    margin: 2em 50px 1em 0; }

.two-column-block__image-col figure {
  display: inline-block;
  text-align: left;
  margin: 0; }

.two-column-block__image-col figcaption {
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #252525;
  padding: 10px 15px; }

@media all and (max-width: 768px) {
  .two-column-block__image-col {
    order: -1;
    text-align: right;
    margin-top: -45px;
    margin-bottom: 58px; }
    .two-column-block__image-col figure {
      width: 100%; }
    .two-column-block__image-col img {
      width: 100%;
      height: auto;
      display: inline-block;
      vertical-align: bottom; } }

@media all and (min-width: 821px) {
  .two-column-block__imageCaptionInner {
    max-width: calc(100% - 420px); } }

@media all and (max-width: 768px) {
  .two-column-block__video-col {
    order: -1;
    margin-top: -45px;
    margin-bottom: 58px; } }

.image-gallery {
  padding: 60px 0 80px;
  background-color: #151515; }
  @media all and (max-width: 568px) {
    .image-gallery {
      padding: 50px 0 46px; } }
  .image-gallery + .rich-text-container {
    padding-top: 0; }

.image-gallery-slider img {
  width: 100%;
  height: auto;
  vertical-align: bottom; }

.image-gallery-slider picture {
  display: block; }

.image-gallery-arrows-wrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0 96px; }
  .image-gallery-arrows-wrapper .slick-arrow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    pointer-events: auto; }
    .image-gallery-arrows-wrapper .slick-arrow::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #F2F2F2;
      border-radius: 100%;
      transition: background-color 0.3s ease, transform 0.3s ease; }
    .image-gallery-arrows-wrapper .slick-arrow::after {
      content: '';
      position: absolute;
      z-index: 1;
      width: 10px;
      height: 10px;
      top: 24px;
      font-size: 0;
      border-left: 1px solid #151515;
      border-bottom: 1px solid #151515; }
    .image-gallery-arrows-wrapper .slick-arrow.slick-prev::after {
      left: 26px;
      transform: rotate(45deg); }
    .image-gallery-arrows-wrapper .slick-arrow.slick-next::after {
      left: 22px;
      transform: rotate(225deg); }
    .image-gallery-arrows-wrapper .slick-arrow:hover::before {
      transform: translate(-50%, -50%) scale(1.14);
      background-color: #f4f4f4; }

.image-gallery-dots-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 23px; }
  @media all and (max-width: 568px) {
    .image-gallery-dots-wrapper {
      margin-top: 24px; } }
  .image-gallery-dots-wrapper .slick-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0; }
    .image-gallery-dots-wrapper .slick-dots li {
      display: inline-block;
      margin: 0 1px; }
      .image-gallery-dots-wrapper .slick-dots li button {
        padding: 17px; }
        .image-gallery-dots-wrapper .slick-dots li button::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 100%;
          opacity: 0.5;
          transition: background-color 0.3s ease; }
      .image-gallery-dots-wrapper .slick-dots li.slick-active button::before, .image-gallery-dots-wrapper .slick-dots li:hover button::before {
        background-color: #fff;
        opacity: 1; }

.contact-details {
  padding: 90px 0;
  background-color: #e8e8e8; }
  .contact-details h2 {
    font-family: 'GT-America-Extended-Light';
    text-transform: none; }
  .contact-details__text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .contact-details__text strong {
      font-family: 'GT-America-Extended-Light'; }
  .contact-details__external-link {
    margin-top: 40px;
    text-transform: uppercase; }
    .contact-details__external-link .icon-flag {
      width: 16px;
      height: 12px; }
    .contact-details__external-link strong {
      margin-left: 5px; }

@media (min-width: 899px) {
  .product-breadcrumb {
    margin-top: 200px; } }

.product-detail {
  padding-top: 32px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column; }
  .product-detail__left {
    order: 3; }
    .product-detail__left .desktop {
      display: none; }
  .product-detail__image .images {
    display: flex;
    justify-content: left;
    position: relative; }
    .product-detail__image .images__secondary {
      display: none; }
    .product-detail__image .images img {
      border-bottom-right-radius: 80px;
      border: 1px solid #e8e8e8;
      max-width: 100%; }
  .product-detail__image--icon {
    position: absolute;
    bottom: 0;
    border-radius: 50px;
    height: 40px;
    min-width: 40px;
    background-color: #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px; }
  .product-detail__image .finish-select {
    padding: 20px 0 15px; }
    .product-detail__image .finish-select ul {
      padding: 0; }
      .product-detail__image .finish-select ul li {
        display: inline-block;
        margin-right: 10px; }
    .product-detail__image .finish-select__item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .product-detail__image .finish-select__item img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin-bottom: 10px;
        border: none;
        padding: 8px; }
    .product-detail__image .finish-select .border {
      font-family: 'GT-America-Expanded-Medium'; }
      .product-detail__image .finish-select .border img {
        border: 1px solid #000; }
  .product-detail__accordians {
    order: 3;
    display: flex;
    flex-direction: column; }
    .product-detail__accordians--wrapper {
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8; }
      .product-detail__accordians--wrapper ul {
        list-style: none; }
        .product-detail__accordians--wrapper ul li {
          letter-spacing: 0.6px;
          color: rgba(0, 0, 0, 0.7); }
          .product-detail__accordians--wrapper ul li:before {
            content: "\2022";
            color: rgba(0, 0, 0, 0.7);
            display: inline-block;
            width: 1em;
            margin-left: -1em; }
    .product-detail__accordians--single {
      padding: 10px 0; }
      .product-detail__accordians--single h4 {
        margin-bottom: 0; }
      .product-detail__accordians--single .accordion {
        cursor: pointer;
        width: 100%;
        transition: 0.2s cubic-bezier(0, 0, 0, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 0; }
        .product-detail__accordians--single .accordion .plus-icon {
          width: 20px;
          height: 20px;
          position: relative; }
          .product-detail__accordians--single .accordion .plus-icon:before, .product-detail__accordians--single .accordion .plus-icon:after {
            content: "";
            position: absolute;
            background-color: #2A2A2A;
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
          .product-detail__accordians--single .accordion .plus-icon:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px; }
          .product-detail__accordians--single .accordion .plus-icon:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px; }
        .product-detail__accordians--single .accordion.active .plus-icon:before {
          transform: rotate(90deg); }
        .product-detail__accordians--single .accordion.active .plus-icon:after {
          transform: rotate(180deg); }
      .product-detail__accordians--single .panel {
        max-height: 0;
        overflow: hidden;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        .product-detail__accordians--single .panel.active {
          max-height: 600px; }
        .product-detail__accordians--single .panel .wrapper {
          padding: 10px 0; }
          .product-detail__accordians--single .panel .wrapper ul {
            list-style: none; }
            .product-detail__accordians--single .panel .wrapper ul li {
              letter-spacing: 0.6px;
              color: rgba(0, 0, 0, 0.7); }
              .product-detail__accordians--single .panel .wrapper ul li:before {
                content: "\2022";
                color: rgba(0, 0, 0, 0.7);
                display: inline-block;
                width: 1em;
                margin-left: -1em; }
              .product-detail__accordians--single .panel .wrapper ul li a {
                color: rgba(0, 0, 0, 0.7); }
          .product-detail__accordians--single .panel .wrapper.download-panel {
            padding-bottom: 0; }
            .product-detail__accordians--single .panel .wrapper.download-panel .product-download {
              padding: 15px;
              font-weight: 700;
              border-top: 1px solid #e8e8e8; }
              .product-detail__accordians--single .panel .wrapper.download-panel .product-download:nth-child(even) {
                background-color: #f4f4f4; }
              .product-detail__accordians--single .panel .wrapper.download-panel .product-download a {
                margin-bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center; }
                .product-detail__accordians--single .panel .wrapper.download-panel .product-download a span {
                  font-size: 10px; }
            .product-detail__accordians--single .panel .wrapper.download-panel .download-button {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top: 1px solid #e8e8e8;
              margin: 0;
              padding: 15px;
              text-transform: uppercase; }
        .product-detail__accordians--single .panel p:last-child {
          margin: 0; }
    .product-detail__accordians .make-enquiry {
      margin-top: 30px; }
  @media (min-width: 899px) {
    .product-detail {
      flex-direction: row; }
      .product-detail .mobile {
        display: none; }
      .product-detail__left {
        order: 0;
        flex: 1;
        margin-right: 10px; }
        .product-detail__left .desktop {
          display: block; }
      .product-detail__image {
        width: 50%;
        flex: 1;
        margin-left: 40px; }
        .product-detail__image .images {
          display: flex;
          flex-direction: column; }
          .product-detail__image .images img {
            border-bottom-right-radius: 160px; } }
