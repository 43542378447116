//layout

$vertical-space: 80px;
$horizontal-space: 32px;

$vertical-space-mob: 64px;
$horizontal-space-mob: 32px;


$base-font-size: 12;
$font-size: 12px;
$max-desktop: 1200px; 
$small-desktop: 980px;
$mid-small-desktop: 820px;
$max-tablet: 768px;
$small: 568px;
$max-mobile: 420px;
$large-desktop: 1400px;

$max-content-width: 1192px;

$max-mobile-nav: 1300px;

$block-padding: 24px;

$spacing: 32px;
$grid-spacing: 16px; //make this 50% of spacing
$spacing-small: 24px;

$curve-mob: 80px;
$curve-desktop: 160px;

//buttons

$button-padding: 19px 32px 17px 32px;

//navigation
$menu-link-padding:                 12px 20px;
$sub-menu-link-padding:             12px 60px;
$breakpoint:                        899px;
$mega-menu-multiLevel-colWidth:     100/3 + 0%;
$mobile-menu-back-height:           calc(1.4em + 40px);
$mobile-menu-back-offset:           calc(0px - (1.4em + 40px));
$menu-mobile-width:                 350px;
$dropdown-bg: #2C2C2C;
$top-nav-color: rgba(0, 0, 0, .7);
$sub-nav-color: rgba(255, 255, 255, .7);
$sub-nav-hover-color: rgba(255, 255, 255, 1);
$sub-nav-hover: #3B3B3B;
$nav-scroll-bg: #000;



//ANIMATIONS 
$duration: .2s;

$easing: cubic-bezier(0, 0, 0, 1);
$extremeEasingOut: cubic-bezier(0,0,0.125,1.06);


//colours

$black: #000;
$background-black: #151515;
$background-black-2: #1C1C1C;
$background-off-white: #F4F4F4;
$background-off-white-2: #E8E8E8;
$white: #fff;
$black-accent: #222222;

$text: #000000;
$text-light: #868686;
$text-footer: #8f8f8f;

$accent: #36C593; //green
$accent-text: #FFF; // text colour on accent bg

$dark-grey: #252525;
$dark-grey-2: #323232;
$dark-grey-3: #606060;
$grey: #2C2C2C;
$light-grey: #D8D8D8;
$lighter-grey: #e8e8e8;
$pale-grey: #f4f4f4;

$btn-hover-grey: #EEEEEE;
$border: #DBDBDB;

$mobile-nav-bg: #141414;
$mobile-sub-bg: #292929;

$bg-light-grey: #FCFCFC;
$bg-light-grey-2: #F2F2F2;

$carousel-arrow: #151515;

$primary-gold: #84754E;

//content

$content-txt: rgba(0, 0, 0, .7);
$content-txt-white: rgba(255, 255, 255, .7);
$caption-txt-grey: rgba(0, 0, 0, .5);
$pod-grey-bg: #e3e3e3;

//form

$input-border: rgba(0, 0, 0, .2);
$txt-area-bg: #eaeaea;
$error-msg: #F43F36;
$error-msg-bg: rgba(221, 21, 21, .1);

//footer vars

$footer-spacing-mob: 40px 0;
$footer-spacing: 100px 0;
$disclaimer-spacing-mob: 20px 0;
$disclaimer-spacing: 30px 0;

$footer: #FFF;
$footer-text: #2A2A2A;
$disclaimer-text: rgba(255, 255, 255, .6);
$disclaimer: #161616;

$dark-theme-bg: #1b1b1b;
$dark-theme-text: #a7a7a7;