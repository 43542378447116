html,
body {
  min-height: 100%;
  font-size: 12px;
  background-color: $background-black;
}

body {
  position: relative;
  width: 100%;
  font-family: 'GT-America-Extended-Light';
  font-weight: 400;
  font-style: normal; 

  &.-nav-open {
    overflow: hidden;
  }
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.svg-sprite {
  display: none;
}


b,
strong {
    font-family: 'GT-America-Expanded-Medium';
}

// Hide SVG Sprite
.svg-sprite {
  display: none;
}

a {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
  }

  &.link-w-icon {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }
}

a:active,
a:hover,
button {
  outline: 0;
}
img {
  border: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


section {
  position: relative;
  // margin: $vertical-space-mob auto;
  
  // @media(min-width: $max-tablet) {
  //   margin: $vertical-space auto;
  // }

  &.no-top {
    margin-top: 0;
  }
  
  &.no-bottom {
    margin-bottom: 0;
  }
}

.container {
  width: 100%;
  max-width: $max-content-width;
  margin: 0 auto; 
  padding: 0 $horizontal-space-mob;
  position: relative;

  &.max-1300 {
    max-width: 1300px;
    padding: 0;
  }
}

.sr-only { //https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.border-top {
  border-top: 1px solid $border;
  padding-top: 60px;
}

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 2;
}

.cursor {
  &--default {
    cursor: default;
  }
}

.skip-to-main {
    position: absolute;
    top: -999999px;
    left: -999999px;
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    z-index: 999999;

    &:focus {
        left: 20px;
        top: 0;
        opacity: 1;
        transform: translateY(20px);
    }

    .button {
        background-color: $background-black;
    }
}

@media (prefers-reduced-motion) {
    body *,
    body *::before,
    body *::after {
        transition: none !important;
        animation-fill-mode: forwards !important;
        animation-iteration-count: 1 !important;
        animation-duration: .01s !important;
    }
}